import { RawPlan } from 'components/context/plan/planContext.types';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRegion } from 'utils/getRegion';

import { useAuthentication } from './useAuthentication/useAuthentication';
import { PlanVariant } from './usePlans';

type useSpecificPlanOptions = {
  planVariant?: PlanVariant;
};

export const useSpecificPlans = ({ planVariant }: useSpecificPlanOptions) => {
  const auth = useAuthentication();
  const params = useParams<{ marketplace: string }>();

  const [plans, setPlans] = useState<null | RawPlan[]>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPlans = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!params.marketplace || !planVariant) {
        return;
      }

      const queryParams = new URLSearchParams({
        region: getRegion(params.marketplace),
        variants: planVariant,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/plans?${queryParams.toString()}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'apikey': auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        return;
      }
      const result = (await response.json()) as RawPlan[];
      setPlans(result);
    } catch (error: unknown) {
      console.error('Something went wrong fetching specific plan');
    } finally {
      setIsLoading(false);
    }
  }, [auth, params, planVariant]);

  useEffect(() => {
    if (!planVariant) {
      return;
    }

    fetchPlans();
  }, [fetchPlans, planVariant]);

  return { plans: plans || null, isLoading: isLoading };
};
