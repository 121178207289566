import { PText } from '@porsche-design-system/components-react';
import React, { ReactNode, useContext } from 'react';

import { chargingOverrides } from '../Charging';

interface SimpleSegmentProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  text?: ReactNode;
  subText?: ReactNode[];
}

/**
 * Single row for the overview table
 * @param props
 * @constructor
 */
export const SimpleSegment: React.FunctionComponent<SimpleSegmentProps> = (
  props,
) => {
  const { components, style } = useContext(chargingOverrides);

  return (
    <div className={style.simpleSegmentRoot}>
      <div>
        <PText weight="semi-bold">{props.title}</PText>
        <PText
          color={'contrast-medium'}
          size={components.simpleSegmentSubTitle.size}
        >
          {props.subTitle}
        </PText>
      </div>
      <div className={style.simpleSegmentTextContainer}>
        <PText weight={'bold'} align={'right'}>
          {props.text}
        </PText>
        <div className={style.simpleSegmentSubTextContainer}>
          {props.subText?.map((item, key) => {
            return (
              <PText
                color={'contrast-medium'}
                key={`${item?.toString()}${key}`}
                align={'right'}
                size={components.simpleSegmentSubText.size}
              >
                {item}
              </PText>
            );
          })}
        </div>
      </div>
    </div>
  );
};
