import {
  PButtonPure,
  PLinkPure,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { messages } from './detailsOverview.messages';
import style from './detailsOverview.module.scss';
import { Header } from './header/Header';
import { Point } from './point/Point';

export const DetailsOverview: React.FunctionComponent = () => {
  const intl = useIntl();
  const params = useParams<{ locale: string; marketplace: string }>();
  const { addMessage } = useToastManager();

  const toClipboard = useCallback(
    (value: string, content: string) => () => {
      navigator.clipboard.writeText(value);
      addMessage({
        text: intl.formatMessage(messages.copiedToClipboard, {
          value: content,
        }),
        state: 'success',
      });
    },
    [],
  );

  return (
    <div className={style.root}>
      <div className={style.table}>
        <Header />
        <Point
          title={intl.formatMessage(messages.point1Title)}
          description={intl.formatMessage(messages.point1Description)}
          index={1}
        />
        <Point
          title={intl.formatMessage(messages.point2Title)}
          description={intl.formatMessage(messages.point2Description)}
          index={2}
        />
        <div className={style.body}>
          <div>
            <PText>
              <FormattedMessage {...messages.bank} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'}>Landesbank Baden-Württemberg</PText>
              <PButtonPure
                hideLabel={true}
                icon="copy"
                onClick={toClipboard(
                  'Landesbank Baden-Württemberg',
                  intl.formatMessage(messages.bank),
                )}
              />
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.iban} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'}>DE16 6005 0101 0008 6894 16</PText>
              <PButtonPure
                hideLabel={true}
                icon="copy"
                onClick={toClipboard(
                  'DE16600501010008689416',
                  intl.formatMessage(messages.iban),
                )}
              />
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.swift} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'}>SOLADEST600</PText>
              <PButtonPure
                hideLabel={true}
                icon="copy"
                onClick={toClipboard(
                  'SOLADEST600',
                  intl.formatMessage(messages.swift),
                )}
              />
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.referenceNumber} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'}>
                <FormattedMessage {...messages.invoiceNumber} />
              </PText>
              <PLinkPure
                href={`${process.env.REACT_APP_OHS_FRONTEND_URL}/${params.marketplace}/${params.locale}/account/orders?type=charging`}
                target="_blank"
                icon="external"
              />
            </div>
          </div>
          <PText
            className={style.disclaimer}
            color={'contrast-medium'}
            size="x-small"
          >
            <FormattedMessage {...messages.referenceNumberDisclaimer} />
          </PText>
        </div>
      </div>
    </div>
  );
};
