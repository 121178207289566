import { PFlyout, PHeading } from '@porsche-design-system/components-react';
import { observer } from 'mobx-react-lite';
import { ReactivateTariff } from 'pages/management/components/ReactivateTariff/ReactivateTariff';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'router/routes';

import { CustomerService } from './customerService/CustomerService';
import { messages } from './flyout.messages';
import style from './flyout.module.scss';
import { TariffOverview } from './overview/TariffOverview';
import { Terminate } from './terminate/Terminate';
import { Reactivate } from '../flyout/reactivate/Reactivate';
import { SuspendedNotificaion } from '../notifications/suspended/Suspended';
import { UpgradeNotification } from '../notifications/UpgradeNotification';
import { PlanContextProvider } from '../../context/plan/PlanContextProvider';
import { subscriptionContext } from '../../context/subscription/SubscriptionContext';

export const OverviewFlyout: React.FunctionComponent = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const isOverview =
    location.pathname.match(/.*\/contract\/overview$/i) !== null;
  const isReactivation =
    location.pathname.match(/.*\/contract\/reactivation$/i) !== null;
  const open = isOverview || isReactivation;
  const [isTerminateOpen, setIsTerminateOpen] = React.useState(false);

  const intl = useIntl();
  const subscription = useContext(subscriptionContext);

  const onClose = useCallback(() => {
    isTerminateOpen ? setIsTerminateOpen(false) : navigate('./..');
  }, [navigate, isTerminateOpen, setIsTerminateOpen]);

  return (
    <PlanContextProvider
      plan={subscription.subscription?.currentOrLastPhase.plan || null}
      marketplace={subscription.subscription?.country || 'US'}
      locale={intl.locale}
    >
      <PFlyout open={open} onDismiss={onClose}>
        <span slot={'header'}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>
        </span>

        {isTerminateOpen ? (
          <Terminate isOpen={isTerminateOpen} setIsOpen={setIsTerminateOpen} />
        ) : (
          <div className={style.root}>
            <ReactivateTariff closeRedirectRoute={Routes.Overview} />

            <SuspendedNotificaion hasAction={true} />
            <UpgradeNotification dismissible={false} />

            <TariffOverview subscription={subscription.subscription} />

            {isReactivation ? <Reactivate /> : null}

            <div>
              <Terminate
                isOpen={isTerminateOpen}
                setIsOpen={setIsTerminateOpen}
              />
            </div>

            <div className={style.customerService}>
              <CustomerService />
            </div>
          </div>
        )}
      </PFlyout>
    </PlanContextProvider>
  );
});
