import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { useCookieConsent } from 'hooks/useCookieConsent';
import { Component, FC, useEffect } from 'react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export const logErrorToNewRelic = (
  error: unknown,
  context?: Record<string, unknown>,
) => {
  if (window.NREUM?.noticeError) {
    window.NREUM.noticeError(
      error instanceof Error ? error : new Error(String(error)),
      context,
    );
  }
};

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    window.NREUM?.noticeError?.(error, { errorInfo });
  }

  render() {
    return this.props.children;
  }
}

export const NewRelic: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { cookieConsentInteracted, hasCookieConsent } =
    useCookieConsent('New Relic');

  useEffect(() => {
    window.onerror = (message, source, lineNo, columnNo, error) => {
      logErrorToNewRelic(error || message, { source, lineNo, columnNo });
    };

    window.onunhandledrejection = (event) => {
      logErrorToNewRelic(event.reason, { type: 'UnhandledPromiseRejection' });
    };

    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  if (process.env.REACT_APP_IS_NEW_RELIC_ENABLED && cookieConsentInteracted) {
    new BrowserAgent({
      init: {
        distributed_tracing: { enabled: true },
        privacy: {
          cookies_enabled: hasCookieConsent,
        },
        ajax: { deny_list: [process.env.REACT_APP_NEW_RELIC_BEACON] },
      },
      info: {
        beacon: process.env.REACT_APP_NEW_RELIC_BEACON,
        errorBeacon: process.env.REACT_APP_NEW_RELIC_BEACON,
        licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
        applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
        sa: 1,
      },
      loader_config: {
        accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
        trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
        agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
        licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
        applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
      },
    });
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};
