import LoadingVehiculeSpinner from 'components/context/vehicle/barrier/LoadingVehicleSpinner';
import { useCanActivateCheck } from 'hooks/useCanActivateCheck';
import { NetworkStatus } from 'hooks/useCombinedNetworking/useCombinedNetworking';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TDefaultParams } from 'router/routes';
import { getIsMarketPlacePCSEnabled } from 'utils/getIsMarketPlacePCSEnabled';

import { useGetConnectRedirectionUrl } from './getConnectRedirectionUrl';
import { Entitlement } from '../../components/context/vehicle/VehicleContext';
import { vehicleContext } from '../../components/context/vehicle/VehicleContextProvider';
import { useAuthentication } from '../../hooks/useAuthentication/useAuthentication';
export const InvitationWrapper: React.FC<{ children: React.ReactNode }> =
  observer(({ children }) => {
    const { entitlement, network } = useContext(vehicleContext);
    const authentication = useAuthentication();

    const { canActivate, isLoading } = useCanActivateCheck();

    const redirectionUrl = useGetConnectRedirectionUrl();
    const { marketplace } = useParams<TDefaultParams>();
    let returnLoadingSpinner = false;

    if (!getIsMarketPlacePCSEnabled(marketplace)) {
      window.location.replace(
        `${redirectionUrl.redirectionIfNoChargingOrder.toString()}`,
      );
      returnLoadingSpinner = true;
    }

    if (
      authentication.token.length > 0 &&
      entitlement === Entitlement.NotEntitled
    ) {
      window.location.replace(
        `${redirectionUrl.redirectionIfNoChargingOrder.toString()}`,
      );
      returnLoadingSpinner = true;
    }

    if (
      returnLoadingSpinner ||
      network === NetworkStatus.Loading ||
      isLoading
    ) {
      return <LoadingVehiculeSpinner />;
    }

    if (canActivate === false) {
      window.location.replace(
        `${redirectionUrl.redirectionIfNoChargingOrder.toString()}`,
      );
    }

    return <>{children}</>;
  });
