import { Region } from 'components/context/plan/planContext.types';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { chargingOverrides } from '../Charging';
import { SimpleSegment } from '../segment/SimpleSegment';
import { planContext } from '../../../context/plan/PlanContext';

/**
 * Display charging cost <br/>
 * Use for na legacy tariff only
 */
export const ChargingNaLegacy: React.FunctionComponent = observer((_props) => {
  const { messages } = useContext(chargingOverrides);
  const context = useContext(planContext);
  const intl = useIntl();

  /**
   * Prices are hardcoded depending on US/CA
   */
  const overview = useMemo(() => {
    /**
     * Get a proper intl provider since the rest of the application formats currency according to locale
     */
    const currency = (() => {
      if (context.region === Region.US) {
        return new Intl.NumberFormat(intl.locale, {
          style: 'currency',
          currency: 'USD',
        });
      }
      return new Intl.NumberFormat(intl.locale, {
        style: 'currency',
        currency: 'CAD',
      });
    })();

    if (context.region === Region.US) {
      return {
        ac: currency.format(0.25),
        dc: currency.format(0.03),
        idle: currency.format(0.4),
      };
    }

    return {
      ac: currency.format(0.25),
      dc: currency.format(0.03),
      idle: currency.format(0.4),
    };
  }, [context.region, intl.locale]);

  return (
    <React.Fragment>
      <div style={{ display: 'none' }} data-testid={'chargingNaLegacy'} />
      <SimpleSegment
        title={<FormattedMessage {...messages.ac} />}
        subTitle={
          <FormattedMessage {...messages.kwComparison} values={{ amount: 7 }} />
        }
        text={
          <FormattedMessage {...messages.flatrate} values={{ amount: 60 }} />
        }
        subText={[
          <FormattedMessage
            {...messages.perMinuteAdditional}
            values={{ amount: overview.ac }}
          />,
        ]}
      />
      <SimpleSegment
        title={<FormattedMessage {...messages.dc} />}
        subTitle={
          <FormattedMessage
            {...messages.kwComparison}
            values={{ amount: 350 }}
          />
        }
        text={
          <FormattedMessage {...messages.flatrate} values={{ amount: 30 }} />
        }
        subText={[
          <FormattedMessage
            {...messages.perMinuteAdditional}
            values={{ amount: overview.dc }}
          />,
        ]}
      />
      <SimpleSegment
        title={<FormattedMessage {...messages.gracePeriodTitle} />}
        text={<FormattedMessage {...messages.gracePeriodText} />}
      />
      <SimpleSegment
        title={<FormattedMessage {...messages.idleFeeTitle} />}
        text={
          <FormattedMessage
            {...messages.perMinute}
            values={{ amount: overview.idle }}
          />
        }
      />
    </React.Fragment>
  );
});
