import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'invitation.title',
    defaultMessage: 'The My Charging Service is ready for you',
    description: 'Title of the invitation page',
  },
  subTitle: {
    id: 'invitation.subTitle',
    defaultMessage: 'Our offer specifically for your {carModel}',
    description: 'Title of the invitation page',
  },
  tariffActivation: {
    id: 'invitation.tariffActivation',
    defaultMessage: 'Tariff Activation',
    description: 'title for tariff activation',
  },
  tariffActivationSubtitle: {
    id: 'invitation.tariffActivationSubtitle',
    defaultMessage: 'Choose a charging tariff to continue.',
    description: 'An invitation message to select a tariff',
  },
  step1: {
    id: 'invitation.stepper.step1',
    defaultMessage: 'Identification',
    description: 'Button with link to first completed step',
  },
  step2: {
    id: 'invitation.stepper.step2',
    defaultMessage: 'Security settings',
    description: 'Button with link to second completed step',
  },
  step3: {
    id: 'invitation.stepper.step3',
    defaultMessage: 'Activation',
    description: 'Button with link to current step',
  },
  step4: {
    id: 'invitation.stepper.step4',
    defaultMessage: 'Activation confirmation',
    description: 'Button with link to last step',
  },
  notificationHeader: {
    id: 'invitation.notificationHeader',
    defaultMessage: 'Porsche Charging Service will be activated.',
    description: 'subscribtion inline notification header',
  },
  notificationBody: {
    id: 'invitation.notificationBody',
    defaultMessage:
      'Please note that charging costs are incurred. Your Porsche Charging Card will be delivered to your preferred address as quickly as possible.',
    description: 'subscribtion inline notification body',
  },
  notificationBodyNorthAmerica: {
    id: 'invitation.notificationBodyNorthAmerica',
    defaultMessage: 'Please note that charging costs are incurred.',
    description:
      'subscribtion inline notification body without the card delivery address',
  },
  legalNotice: {
    id: 'invitation.legalNotice',
    defaultMessage:
      'Porsche My Charging is distributed by Porsche Sales & Marketplace GmbH, Porscheplatz 1, 70435 Stuttgart, Germany',
    description: 'legal notice',
  },
  toCConfirm: {
    id: 'invitation.toCConfirm',
    defaultMessage:
      'I have read and agree to the General Terms and Conditions and the Terms of Use.',
    description: 'accepting the terms and conditions',
  },
  privacyPolicyConfirm: {
    id: 'invitation.privacyPolicyConfirm',
    defaultMessage: 'I have read the Privacy Policy note.',
    description: 'accepting the privacy policy',
  },
  submitButton: {
    id: 'invitation.submitButton',
    defaultMessage: 'Complete Order',
    description: 'Submit button',
  },
  skipButton: {
    id: 'invitation.skipButton',
    defaultMessage: 'Skip Charging Services Activation',
    description: 'skip charging services activation button',
  },
  offlineErrorTitle: {
    id: 'invitation.offlineErrorTitle',
    defaultMessage: 'No internet connection.',
    description: 'offline error title',
  },
  offlineErrorBody: {
    id: 'invitation.offlineErrorBody',
    defaultMessage:
      'It seems like you’re disconnected. Please make sure you’re connected to the internet to proceed.',
    description: 'Offline error body',
  },
  acceptToCMessage: {
    id: 'invitation.acceptToCMessage',
    defaultMessage:
      'Please accept the Terms of use and Privacy policy to activate the service',
    description: 'Accepting terms and conditions massage',
  },
  chargingTarrifSelectTitle: {
    id: 'invitation.chargingTarrifSelectTitle',
    defaultMessage: 'Choose a charging tariff',
    description: 'Choose a charging tarriff section title',
  },
  billingAdressSelectTitle: {
    id: 'invitation.billingAdressSelectTitle',
    defaultMessage: 'Billing address',
    description: 'Choose a billing address section title',
  },
  rfidAdressSelectTitle: {
    id: 'invitation.rfidAdressSelectTitle',
    defaultMessage: 'Delivery address from Porsche Charging Card',
    description: 'Choose a rfid delivery address section title',
  },
  selectPaymentMethodTitle: {
    id: 'invitation.selectPaymentMethodTitle',
    defaultMessage: 'Payment method',
    description: 'Choose a payment method section title',
  },
  tacTitle: {
    id: 'invitation.tacTitle',
    defaultMessage: 'Terms & conditions',
    description: 'Accept terms and conditions title',
  },
  activateServiceActionButtonLabel: {
    id: 'invitation.activateServiceActionButtonLabel',
    defaultMessage: 'Activate service',
    description: 'Activate service action button label',
  },
  sectionDefaultActionButtonLabel: {
    id: 'invitation.sectionDefaultActionButtonLabel',
    defaultMessage: 'Save & Continue',
    description: 'Confirm seving selected data in a section',
  },
  paymentSectionUnknownPaymentMethod: {
    id: 'invitation.paymentSectionUnknownPaymentMethod',
    defaultMessage: 'Unknown payment method',
    description: 'Section subtext for unknown payment method',
  },
  toggleOpenButtonLabel: {
    id: 'invitation.toggleOpenButtonLabel',
    defaultMessage: 'Edit section',
    description: 'Label for button',
  },
});
