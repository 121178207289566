import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext } from 'react';

import { planContext } from '../../PlanContext';
import { Region } from '../../planContext.types';

interface Props {
  children?: ReactNode;
}

export const EuropeOnly: React.FunctionComponent<Props> = observer((props) => {
  const context = useContext(planContext);

  if (context.region === Region.EU) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return null;
});
