import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './notificationExtendToNew.messages';
import { InlineNotification } from '../InlineNotification';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';

interface NotificationExtendNewProps {
  dismissible: boolean;
}

export const NotificationExtendToNew: React.FunctionComponent<
  NotificationExtendNewProps
> = (props) => {
  const intl = useIntl();
  const subscription = useContext(subscriptionContext);

  const title = useMemo(() => {
    return intl.formatMessage(messages.title, {
      date: intl.formatDate(
        new Date(subscription.subscription?.currentOrLastPhase.endDate || ''),
        {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        },
      ),
    });
  }, [intl, subscription.subscription?.currentOrLastPhase.endDate]);

  if (
    !subscription.subscription ||
    !subscription.subscription.currentOrLastPhase.endDate
  ) {
    return null;
  }

  return (
    <InlineNotification
      title={title}
      text={intl.formatMessage(messages.text)}
      actionLabel={intl.formatMessage(messages.action)}
      state={'warning'}
      dismissibleId={props.dismissible ? 'extend' : undefined}
    />
  );
};
