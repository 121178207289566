import React from 'react';

import style from './style.module.scss';
const PlanCardsSkeleton = () => {
  return (
    <>
      <div className={style.skeletonHeader}>
        <div className={style.skeletonBox} />
        <div className={style.skeletonBox} />
      </div>
      <div className={style.plansSkeleton}>
        <div>
          <div className={style.skeletonBox} />
          <div className={style.skeletonBox} />
          <div className={style.skeletonBox} />
        </div>
        <div>
          <div className={style.skeletonBox} />
          <div className={style.skeletonBox} />
          <div className={style.skeletonBox} />
        </div>
      </div>
    </>
  );
};

export default PlanCardsSkeleton;
