import { Authentication } from 'hooks/useAuthentication/useAuthentication';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { fetchApi } from 'utils/fetchApi';

type AvailableFeatureFlags = {
  chargePoint?: { enabled: boolean };
  termsAndConditionsUpdate?: { enabled: boolean };
  invitationRedesign?: { enabled: boolean };
};

export class FeatureFlagsContext {
  public featureFlags: AvailableFeatureFlags = {};
  private auth: Authentication = {} as Authentication;

  constructor(props: { dummy: boolean; auth?: Authentication }) {
    makeAutoObservable(this);

    this.auth = props.auth || ({} as Authentication);

    if (props.dummy || !props.auth?.token) {
      return;
    }

    this.fetchData();
  }

  private fetchData = async () => {
    try {
      const featureFlagsResponse = await fetchApi(
        `${process.env.REACT_APP_BASE_API_URL}/feature-flags`,
        this.auth.token,
        {
          method: 'GET',
        },
      );

      const featureFlagsResponseJson = await featureFlagsResponse.json();

      this.featureFlags = featureFlagsResponseJson as AvailableFeatureFlags;
    } catch (e: unknown) {
      console.error(e);
      this.featureFlags = {};
    }
  };
}

export const featureFlagsDummyContext = new FeatureFlagsContext({
  dummy: true,
  auth: {} as Authentication,
});

export const featureFlagsContext = createContext<FeatureFlagsContext>(
  featureFlagsDummyContext,
);
