import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { TermsAndConditionsAcceptPage } from './termsAndConditionsAccept/TermsAndConditionsAcceptPage';
import { SubscriptionContextProvider } from '../../components/context/subscription/SubscriptionContextProvider';

export const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <SubscriptionContextProvider>
      <Routes>
        <Route element={<TermsAndConditionsAcceptPage />} path={'*'} />
      </Routes>
    </SubscriptionContextProvider>
  );
};
