import { Step, Stepper } from 'components/stepper/Stepper';
import { FC } from 'react';

import { FailureComponent } from './failureComponent/FailureComponent';

export const FailurePage: FC = () => {
  return (
    <div data-testid={'failurePage'}>
      <Stepper currentStep={Step.Confirmation} />
      <FailureComponent />
    </div>
  );
};
