import { RawPlan } from 'components/context/plan/planContext.types';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRegion } from 'utils/getRegion';

import { useAuthentication } from './useAuthentication/useAuthentication';
import { PlanVariant } from './usePlans';

type useSpecificPlanOptions = {
  planVariant: PlanVariant;
  shouldPreventPlanSearch?: boolean;
};

export const useSpecificPlan = ({
  planVariant,
  shouldPreventPlanSearch = false,
}: useSpecificPlanOptions) => {
  const auth = useAuthentication();
  const params = useParams<{ marketplace: string }>();

  const [plan, setPlan] = useState<null | RawPlan>(null);

  const fetchPlan = useCallback(async () => {
    try {
      if (!params.marketplace) {
        return;
      }

      const queryParams = new URLSearchParams({
        region: getRegion(params.marketplace),
        variants: planVariant,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/plans?${queryParams.toString()}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'apikey': auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        return;
      }
      const result = (await response.json()) as RawPlan[];
      setPlan(result[0]);
    } catch (error: unknown) {
      console.error('Something went wrong fetching specific plan');
    }
  }, [auth, params, planVariant]);

  useEffect(() => {
    if (shouldPreventPlanSearch) {
      return;
    }

    fetchPlan();
  }, [fetchPlan, shouldPreventPlanSearch]);

  return plan || null;
};
