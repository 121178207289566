import { PTag } from '@porsche-design-system/components-react';
import { Subscription } from 'components/context/subscription/SubscriptionContext';
import { useShouldShowPriceUpdate } from 'hooks/useShouldShowPriceUpdate';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './priceChangeTag.messages';

type PriceChangeTagProps = {
  subscription: Subscription;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export const PriceChangeTag: FC<PriceChangeTagProps> = ({
  subscription,
  className,
}) => {
  const { shouldShowPriceUpdate, dateOfPriceUpdate } = useShouldShowPriceUpdate(
    { subscription },
  );
  const formattedDate =
    dateOfPriceUpdate &&
    Intl.DateTimeFormat('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(dateOfPriceUpdate));

  if (!shouldShowPriceUpdate || !dateOfPriceUpdate) {
    return null;
  }

  return (
    <PTag
      color={'notification-info-soft'}
      icon={'information'}
      className={className}
    >
      <FormattedMessage
        {...messages.mainMessage}
        values={{ date: formattedDate }}
      />
    </PTag>
  );
};
