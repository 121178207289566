import { PText } from '@porsche-design-system/components-react';
import { useSpecificPlan } from 'hooks/useSpecificPlan';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import style from './tariffSelection.module.scss';
import {
  PlanVariant,
  RawPlan,
} from '../../../../components/context/plan/planContext.types';
import { PlanContextProvider } from '../../../../components/context/plan/PlanContextProvider';
import { Disclaimer } from '../../../../components/tariff/components/disclaimer/Disclaimer';
import { InlineTariff } from '../../../../components/tariff/inline/InlineTariff';

interface TariffSelectionProps {
  /**
   * Current selected plan variants
   */
  planVariant: PlanVariant;
  /**
   * Onchange callback
   * @param planVariant
   */
  onChange: (planVariant: PlanVariant) => void;
  /**
   * Display plan prices for passed marketplace
   */
  marketplace: string;
  /**
   * Plans the user can choose, between 1-2 plans <br/>
   * A single plan will be displayed properly, however can't be selected
   */
  plans: RawPlan[];
  /**
   * Disable plan selection ui
   */
  disabled: boolean;
}

export const TariffSelection: React.FunctionComponent<TariffSelectionProps> =
  observer((props) => {
    const intl = useIntl();
    const shouldPreventV2PremiumPlanSearch =
      props.plans.filter((singlePlan) =>
        [
          PlanVariant.V1_PREMIUM_INCLUSIVE,
          PlanVariant.V2_PREMIUM_INCLUSIVE,
        ].includes(singlePlan.variant),
      ).length === 0;
    const v2PremiumPlan = useSpecificPlan({
      planVariant: PlanVariant.V2_PREMIUM,
      shouldPreventPlanSearch: shouldPreventV2PremiumPlanSearch,
    });

    const selectFirst = useCallback(() => {
      props.onChange(props.plans[0].variant);
    }, [props]);

    const selectSecond = useCallback(() => {
      props.onChange(props.plans[1].variant);
    }, [props]);

    const disabledStyle = useMemo(() => {
      if (props.disabled) {
        return style.disabled;
      }
      return '';
    }, [props.disabled]);

    if (props.plans.length === 0) {
      console.error('<TariffSelection/> Received zero plans');
      return null;
    }

    if (props.plans.length === 1) {
      return (
        <div className={disabledStyle}>
          <InlineTariff marketplace={props.marketplace} plan={props.plans[0]} />
        </div>
      );
    }

    return (
      <div className={style.disclaimer}>
        <div className={disabledStyle}>
          <div
            className={
              props.planVariant === props.plans[0].variant
                ? style.firstSelected
                : style.secondSelected
            }
          >
            <div onClick={selectFirst}>
              <InlineTariff
                marketplace={props.marketplace}
                plan={props.plans[0]}
                removeDisclaimer={true}
                v2PremiumPlan={v2PremiumPlan}
              />
            </div>
            <div onClick={selectSecond}>
              <InlineTariff
                marketplace={props.marketplace}
                plan={props.plans[1]}
                removeDisclaimer={true}
                v2PremiumPlan={v2PremiumPlan}
              />
            </div>
          </div>
        </div>
        <PText size={'x-small'} color={'contrast-medium'}>
          <PlanContextProvider
            plan={props.plans[0]}
            marketplace={props.marketplace}
            locale={intl.locale}
          >
            <Disclaimer />
          </PlanContextProvider>
        </PText>
      </div>
    );
  });
