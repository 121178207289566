import { PInlineNotification } from '@porsche-design-system/components-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { messages } from './suspended.messages';
import { messages as managementMessages } from '../../../../pages/management/management.module.messages';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';

interface SuspendedNotificaionProps {
  hasAction?: boolean;
}

export const SuspendedNotificaion: React.FunctionComponent<SuspendedNotificaionProps> =
  observer(({ hasAction = false }) => {
    const intl = useIntl();
    const subscription = useContext(subscriptionContext);
    const navigate = useNavigate();

    const handleAction = useCallback(() => {
      navigate('./../suspended');
    }, [navigate]);

    if (
      !subscription.subscription?.isSuspended &&
      !subscription.hasSuspendedContracts
    ) {
      return null;
    }

    return (
      <PInlineNotification
        heading={intl.formatMessage(
          subscription.subscription?.isSuspended
            ? messages.heading
            : messages.heading2,
        )}
        description={intl.formatMessage(
          subscription.subscription?.isSuspended
            ? messages.description
            : messages.description2,
        )}
        state={'error'}
        dismissButton={false}
        actionLabel={
          hasAction
            ? intl.formatMessage(managementMessages.suspendedNotificationAction)
            : undefined
        }
        actionIcon="arrow-right"
        onAction={handleAction}
      />
    );
  });
