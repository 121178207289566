import {
  PCheckboxWrapper,
  PDivider,
} from '@porsche-design-system/components-react';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useFetchAddress } from 'hooks/useFetchAddress';
import { observer } from 'mobx-react-lite';
import { Address } from 'pages/activation/components/AddressPreview';
import { AddressWidget } from 'pages/activation/components/AddressWidget';
import { TAddressChangeEventPayload } from 'pages/activation/components/AddressWidget';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './adressForm.messages';
import style from './style.module.scss';

type CardAddressProps = {
  isRedesign?: boolean;
  currentAddress?: Address | null;
  isChecked?: boolean | null;
  onAddressChange?: (address: Address) => void;
  toggleIsChecked?: () => void;
};

const CardAddress: FC<CardAddressProps> = observer(
  ({
    isRedesign,
    currentAddress,
    isChecked,
    onAddressChange,
    toggleIsChecked,
  }) => {
    const intl = useIntl();
    const authentication = useAuthentication();
    const { fetchAddressById } = useFetchAddress();
    const [
      cardDeliveryAddressSameAsBilling,
      setCardDeliveryAddressSameAsBilling,
    ] = useState(true);

    const handleAddressChange = async (
      addressPayload: TAddressChangeEventPayload,
    ) => {
      try {
        const fullAddress = await fetchAddressById(
          typeof addressPayload === 'object'
            ? addressPayload?.selectedAddressId
            : addressPayload,
        );

        if (onAddressChange) {
          onAddressChange(fullAddress!);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (isRedesign && isChecked === null) {
        toggleIsChecked?.();
      }
    }, [isRedesign, isChecked, toggleIsChecked]);

    return (
      <section className={style.pb3}>
        <PCheckboxWrapper
          className={style.pb2}
          hideLabel={false}
          label={intl.formatMessage(messages.sameAddressCheck)}
        >
          <input
            type="checkbox"
            name="cardDeliveryAddressSameAsBilling"
            checked={
              isRedesign ? !!isChecked : cardDeliveryAddressSameAsBilling
            }
            onChange={() =>
              isRedesign
                ? toggleIsChecked?.()
                : setCardDeliveryAddressSameAsBilling(
                    !cardDeliveryAddressSameAsBilling,
                  )
            }
          />
        </PCheckboxWrapper>

        {isRedesign ? null : (
          <PDivider color="contrast-low" className={style.py1} />
        )}

        {(!cardDeliveryAddressSameAsBilling || (isRedesign && !isChecked)) && (
          <AddressWidget
            authorizationToken={authentication.token}
            name="cardAddressId"
            identifier="cardAddressId"
            defaultAddressId={currentAddress?.addressId}
            onChange={isRedesign ? handleAddressChange : undefined}
          />
        )}
      </section>
    );
  },
);

export default CardAddress;
