import {
  PButton,
  PFlyout,
  PHeading,
  PInlineNotification,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react';
import { planContext } from 'components/context/plan/PlanContext';
import { BaseFee } from 'components/manage/overviewFlyout/overview/baseFee/BaseFee';
import { FlatTariff } from 'components/manage/overviewFlyout/overview/flat/FlatTariff';
import { TermsAndConditions } from 'components/tac/TermsAndConditions';
import { Charging } from 'components/tariff/charging/Charging';
import { TariffName } from 'components/tariff/components/name/TariffName';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useRoutes } from 'hooks/useRoutes';
import { observer } from 'mobx-react-lite';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from 'router/routes';

import { messages } from './reactivate.messages';
import style from './reactivate.module.scss';
import { messages as baseFeeMessages } from '../../overviewFlyout/overview/baseFee/baseFee.messages';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';

export const Reactivate: React.FunctionComponent = observer((_props) => {
  const generateRoute = useRoutes();
  const [locked, setLocked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [actionError, setActionError] = useState(false);
  const auth = useAuthentication();
  const currentSubscriptionContext = useContext(subscriptionContext);
  const currentPlanContext = useContext(planContext);
  const navigate = useNavigate();
  const [tac, setTac] = useState(false);
  const [searchParams] = useSearchParams();
  const { addMessage } = useToastManager();
  const intl = useIntl();

  const onClose = () => {
    const searchParamBackUrl = searchParams.get('backUrl');
    const matchingRoute = (
      Object.keys(Routes) as Array<keyof typeof Routes>
    ).find((routeKey) => Routes[routeKey] === searchParamBackUrl);

    navigate(generateRoute(Routes[matchingRoute || 'Overview']));
  };

  const onActivate = useCallback(async () => {
    if (!currentSubscriptionContext.subscription) {
      return;
    }

    setLocked(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${currentSubscriptionContext.subscription.id}/reactivate`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'apikey': auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      const updatedSubscription = await response.json();

      currentSubscriptionContext.updateSubscription(updatedSubscription);

      addMessage({
        text: intl.formatMessage(messages.successMessage),
        state: 'success',
      });

      setActionError(false);
      setSuccess(true);
      onClose();
    } catch (error: unknown) {
      console.error(error);
      setActionError(true);
    } finally {
      setLocked(false);
    }
  }, [currentSubscriptionContext, auth]);

  return (
    <PFlyout open={true} onDismiss={onClose}>
      <span slot={'header'}>
        <PHeading className={style.title}>
          <FormattedMessage {...messages.title} />
        </PHeading>

        <PText className={style.subtitle}>
          <FormattedMessage {...messages.subtitle} />
        </PText>
      </span>

      <div className={style.floutContentWrapper}>
        {actionError ? (
          <PInlineNotification
            state={'error'}
            className={style.error}
            dismissButton={false}
          >
            <p className={style.errorTitle}>
              <FormattedMessage {...messages.errorTitle} />
            </p>

            <FormattedMessage
              {...messages.errorSubtitle}
              values={{
                customerSupport: (
                  <a href="">
                    <FormattedMessage {...messages.customerSupport} />
                  </a>
                ),
              }}
            />
          </PInlineNotification>
        ) : null}

        <div className={style.table}>
          <div>
            <PHeading className={style.tableTitle} size={'large'}>
              <TariffName />
            </PHeading>

            <PText>
              <FormattedMessage
                {...baseFeeMessages.monthlyText}
                values={{ baseFee: currentPlanContext.baseFee }}
              />
            </PText>
          </div>

          <div className={style.body}>
            <BaseFee />
            <Charging />
            <FlatTariff />
          </div>
        </div>

        <TermsAndConditions
          checked={tac}
          onChange={() => setTac((preValue) => !preValue)}
          className={style.pushed}
        />

        <div className={style.actionsWrapper}>
          <PButton
            onClick={() => onActivate()}
            className={style.mainActionButton}
            icon="reset"
            disabled={!tac || locked || success}
            loading={locked}
          >
            <FormattedMessage {...messages.reactivateButtonLabel} />
          </PButton>

          <PButton onClick={onClose} variant="secondary">
            <FormattedMessage {...messages.cancelButtonLabel} />
          </PButton>
        </div>

        <AdditionalDisclaimer planVariants={[currentPlanContext.variant]} />
      </div>
    </PFlyout>
  );
});
