import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './inclusivePeriod.messages';
import styles from './inclusivePeriod.module.scss';
import { PlanContext } from '../../../../context/plan/PlanContext';
import { subscriptionContext } from '../../../../context/subscription/SubscriptionContext';

/**
 * Displays subtext with free of charge period and follow-up pricing
 */
export const InclusivePeriod: React.FunctionComponent = observer(() => {
  const intl = useIntl();
  const inclusive = useContext(subscriptionContext).getInclusivePeriod();

  const afterwards = useMemo(() => {
    if (!inclusive || !inclusive.afterwards) {
      return null;
    }

    const plan = new PlanContext({
      plan: inclusive.afterwards.rawPlan,
      marketplace: inclusive.afterwards.marketplace,
      locale: intl.locale,
    });

    return (
      <div className={styles.text}>
        <FormattedMessage
          {...messages.afterwardsMonthly}
          values={{ amount: plan.baseFee }}
        />
      </div>
    );
  }, [inclusive, intl.locale]);

  if (!inclusive) {
    return null;
  }

  return (
    <>
      <div className={styles.text}>
        <FormattedMessage
          {...messages.freeUntil}
          values={{
            date: intl.formatDate(inclusive.freeUntil, {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
            }),
          }}
        />
      </div>
      {afterwards}
    </>
  );
});
