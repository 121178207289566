import {
  PButtonPure,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { subscriptionContext } from 'components/context/subscription/SubscriptionContext';
import { NextPlanDisclaimer } from 'components/tariff/components/name/NextPlanDisclaimer';
import { PlanVariant } from 'hooks/usePlans';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { messages } from './tariff.messages';
import style from './tariff.module.scss';
import { useRoutes } from '../../../../../hooks/useRoutes';
import { Routes } from '../../../../../router/routes';
import { upgradeContext } from '../../../../context/upgrade/UpgradeContextProvider';
import { TariffName } from '../../../../tariff/components/name/TariffName';

export const Tariff: React.FunctionComponent = observer(() => {
  const context = useContext(upgradeContext);
  const subscription = useContext(subscriptionContext);
  const generateRoute = useRoutes();
  const now = new Date();
  const endDate =
    (context.subscription?.endDate &&
      new Date(context.subscription?.endDate)) ||
    now;

  const isReactivationTagActive =
    context.subscription?.isTerminated &&
    context.subscription?.endDate &&
    now < endDate &&
    PlanVariant.V1_PREMIUM_INCLUSIVE !==
      context.subscription.currentOrLastPhase?.plan?.variant;

  const manageButton = useMemo(() => {
    if (
      context.planVariants.length === 0 ||
      subscription.subscription?.isSuspended
    ) {
      return null;
    }
    return (
      <Link
        to={generateRoute(Routes.ChangeTariff)}
        className={style.manageButton}
      >
        <PButtonPure icon={'arrow-right'}>
          <FormattedMessage {...messages.action} />
        </PButtonPure>
      </Link>
    );
  }, [context.planVariants, generateRoute]);

  const tag = useMemo(() => {
    if (!isReactivationTagActive) {
      return null;
    }

    return (
      <PTag color={'notification-warning-soft'} icon={'information'}>
        <FormattedMessage {...messages.terminationProgress} />
      </PTag>
    );
  }, [isReactivationTagActive]);

  return (
    <div data-testid={'tariff'}>
      <PText>
        <FormattedMessage {...messages.title} />
      </PText>

      {tag}

      {subscription.subscription?.isSuspended ? (
        <PTag color="notification-error-soft" className={style.suspendedTag}>
          <FormattedMessage {...messages.suspendedUpdate} />
        </PTag>
      ) : null}
      <div className={style.row}>
        <PText weight={'bold'} align={'right'}>
          <TariffName />
          {!subscription.subscription?.isSuspended ? (
            <PText
              className={style.disclaimer}
              color={'contrast-medium'}
              align={'right'}
            >
              <NextPlanDisclaimer />
            </PText>
          ) : null}
        </PText>
        {isReactivationTagActive ? null : manageButton}
      </div>
    </div>
  );
});
