import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext } from 'react';

import { planContext } from '../../PlanContext';
import { Region } from '../../planContext.types';

interface Props {
  children?: ReactNode;
}

export const UnitedStatesOnly: React.FunctionComponent<Props> = observer(
  (props) => {
    const context = useContext(planContext);

    if (context.region === Region.US) {
      return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
  },
);
