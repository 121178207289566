import { PCarousel, PIcon } from '@porsche-design-system/components-react';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { Region } from 'hooks/usePlans';
import { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './invitationBanner.messages';
import style from './invitationBanner.module.scss';
import background from '../../../../media/invitationBannerImage.png';

type InvitationBannerProps = {
  region?: string;
};

export const InvitationBanner: FC<InvitationBannerProps> = ({ region }) => {
  const { inclusivePeriodUntil } = useContext(vehicleContext);
  const intl = useIntl();

  return (
    <div className={style.contentContainer}>
      <div className={style.imageContainer} style={{ width: '50%' }}>
        <img
          src={background}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '4px 0 0 4px',
          }}
        />
      </div>

      <div className={style.imageTextContainer} style={{ width: '50%' }}>
        <div>
          <div className={style.title}>
            <FormattedMessage
              {...(inclusivePeriodUntil
                ? messages.title
                : messages.exclusiveTitle)}
              values={{
                endDate: inclusivePeriodUntil
                  ? intl.formatDate(new Date(inclusivePeriodUntil), {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  : null,
              }}
            />
          </div>
        </div>
        {region === Region.EU ? (
          <div className={style.textWithIconContainer}>
            <PIcon name="charging-station" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle1} />
            </div>
          </div>
        ) : null}
        <div className={style.textWithIconContainer}>
          <PIcon name="purchase" color="contrast-low" size="medium" />
          <div className={style.text}>
            <FormattedMessage {...messages.subTitle2} />
          </div>
        </div>
        <div className={style.textWithIconContainer}>
          <PIcon name="duration" color="contrast-low" size="medium" />
          <div className={style.text}>
            <FormattedMessage {...messages.subTitle3} />
          </div>
        </div>

        <div className={style.absoluteColorGradient}></div>
      </div>

      <div className={style.mobileViewContainer}>
        <div>
          <div className={style.title}>
            <FormattedMessage
              {...(inclusivePeriodUntil
                ? messages.title
                : messages.exclusiveTitle)}
              values={{
                endDate: inclusivePeriodUntil
                  ? intl.formatDate(new Date(inclusivePeriodUntil), {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  : null,
              }}
            />
          </div>
        </div>

        <PCarousel>
          {region === Region.EU ? (
            <div className={style.textWithIconContainerMobile}>
              <PIcon
                name="charging-station"
                color="contrast-low"
                size="medium"
              />
              <div className={style.text}>
                <FormattedMessage {...messages.subTitle1} />
              </div>
            </div>
          ) : null}
          <div className={style.textWithIconContainerMobile}>
            <PIcon name="purchase" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle2} />
            </div>
          </div>
          <div className={style.textWithIconContainerMobile}>
            <PIcon name="duration" color="contrast-low" size="medium" />
            <div className={style.text}>
              <FormattedMessage {...messages.subTitle3} />
            </div>
          </div>
        </PCarousel>
      </div>
    </div>
  );
};
