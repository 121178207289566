import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { chargingOverrides } from '../Charging';
import { PriceSegment } from '../segment/PriceSegment';
import { planContext } from '../../../context/plan/PlanContext';
import {
  BlockingPrefixed,
  ChargePointClass,
} from '../../../context/plan/planContext.types';

/**
 * Display charging cost <br/>
 * Use for eu basic tariff only
 */
export const ChargingEuBasic: React.FunctionComponent = observer(() => {
  const context = useContext(planContext);
  const { messages } = useContext(chargingOverrides);

  const overview = {
    ac: {
      price: context.resolveEnergyPrice(ChargePointClass.AC),
      blocking: [context.resolveBlocking(ChargePointClass.AC)],
    },
    dc: {
      price: context.resolveEnergyPrice(ChargePointClass.DC),
      blocking: [context.resolveBlocking(ChargePointClass.DC)],
    },
    lounge: {
      price: context.resolveEnergyPrice(ChargePointClass.LOUNGE),
      blocking: [
        {
          ...context.resolveBlocking(ChargePointClass.AC),
          prefix: (
            <>
              <FormattedMessage {...messages.acShort} />:{' '}
            </>
          ),
        } as BlockingPrefixed,
        {
          ...context.resolveBlocking(ChargePointClass.DC),
          prefix: (
            <>
              <FormattedMessage {...messages.dcShort} />:{' '}
            </>
          ),
        } as BlockingPrefixed,
      ],
    },
  };

  return (
    <React.Fragment>
      <div style={{ display: 'none' }} data-testid={'chargingEuBasic'} />
      <PriceSegment
        title={<FormattedMessage {...messages.ac} />}
        {...overview.ac}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.dc} />}
        {...overview.dc}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.loungeTitle} />}
        {...overview.lounge}
      />
    </React.Fragment>
  );
});
