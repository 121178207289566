import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'paimentForm.title',
    defaultMessage: 'Select a payment method',
    description: 'Title for the paiment selector',
  },
  subtitle: {
    id: 'paimentForm.subtitle',
    defaultMessage: 'Set a new payment method or select an existing one',
    description: 'Subtitle for the paiment selector',
  },
  paymentMethodExplanationText: {
    id: 'paimentForm.paymentMethodExplanationText',
    defaultMessage:
      'Choose the payment method you want to use for paying the charging services invoices. After inclusive period of three years, you’ll be charged via the selected payment method,',
    description: 'Payment method explanation text',
  },
});
