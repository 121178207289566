import { useSearchParams } from 'react-router-dom';
import { decodeURIUntilStable } from 'utils/decodeURIUntilStable';

import { getPcsLocalesIfCountryEnabled } from '@mycharging/shared/src/marketplaceConfig/config/marketplaceConfig';
import { PcsLocale } from '@mycharging/shared/src/marketplaceConfig/config/pcsLocales';

const MyPortalUrl = process.env.REACT_APP_MY_PORSCHE_URL!;

export const useGetConnectRedirectionUrl = () => {
  const [urlSearchParams] = useSearchParams();
  const encodedRedirectionBaseUrl = urlSearchParams.get('redirect');

  const redirectionBaseUrl = decodeURIUntilStable(
    encodedRedirectionBaseUrl || '',
  );

  const marketplace = redirectionBaseUrl?.split('/')?.[4];
  const locale = redirectionBaseUrl?.split('/')?.[5];

  let myPortalLocalizedUrl = MyPortalUrl;

  if (marketplace) {
    const marketplaceLocales = getPcsLocalesIfCountryEnabled(
      marketplace.toLocaleLowerCase(),
    );

    if (
      marketplaceLocales !== null &&
      marketplaceLocales.includes(locale as PcsLocale)
    ) {
      myPortalLocalizedUrl = `${MyPortalUrl}/${marketplace}/${locale}`;
    }
  }

  try {
    const redirectionUrl = new URL(redirectionBaseUrl || myPortalLocalizedUrl);

    if (redirectionUrl.protocol !== 'https:')
      throw new Error('Invalid protocol');

    if (!redirectionUrl) throw new Error('No redirection url');

    return {
      defaultRedirection: redirectionUrl.toString(),
      redirectionIfNoChargingOrder: urlSearchParams.get('orders')
        ? redirectionUrl.toString()
        : myPortalLocalizedUrl,
    };
  } catch (error) {
    return {
      defaultRedirection: myPortalLocalizedUrl,
      redirectionIfNoChargingOrder: myPortalLocalizedUrl,
    };
  }
};
