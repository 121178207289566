import { PHeading } from '@porsche-design-system/components-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './title.messages';

export const Title: React.FunctionComponent = () => {
  return (
    <PHeading>
      <FormattedMessage {...messages.select} />
    </PHeading>
  );
};
