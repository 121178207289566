import LoadingVehiculeSpinner from 'components/context/vehicle/barrier/LoadingVehicleSpinner';
import { useCanActivateCheck } from 'hooks/useCanActivateCheck';
import { Checkout } from 'pages/activation/Checkout';
import { FailureComponent } from 'pages/confirmation/failure/failureComponent/FailureComponent';
import { PlanVariant } from 'pages/tariff/select/SelectPage';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuthentication } from '../../hooks/useAuthentication/useAuthentication';
import { useRoutes } from '../../hooks/useRoutes';
import { Routes } from '../../router/routes';

export const ActivationPage: React.FunctionComponent = () => {
  const { marketplace, locale, vin } = useParams();
  const [searchParams] = useSearchParams();
  const authentication = useAuthentication();
  const { canActivate, isLoading, errorCode, suspendedVin } =
    useCanActivateCheck();
  const navigate = useNavigate();
  const routes = useRoutes();
  const selected = searchParams.get('selected');

  // Redirect to welcome page if selected tariff is not set
  useEffect(() => {
    if (selected) {
      return;
    }
    navigate(routes(Routes.TariffWelcome));
  }, [navigate, routes, selected]);

  if (isLoading) {
    return <LoadingVehiculeSpinner />;
  }

  if (canActivate === false) {
    return (
      <FailureComponent
        errorCodeOverride={errorCode || undefined}
        suspendedVin={
          !!suspendedVin && suspendedVin !== vin ? suspendedVin : undefined
        }
      />
    );
  }

  return (
    <Checkout
      authorizationToken={authentication.token}
      country={marketplace?.toUpperCase() || ''}
      locale={locale || 'de_DE'}
      planVariant={(searchParams.get('selected') as PlanVariant) || ''}
      vin={vin || ''}
      widgetApiKey={authentication.apiKey}
    />
  );
};
