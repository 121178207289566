import {
  PButtonPure,
  PDivider,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';
import { planContext } from 'components/context/plan/PlanContext';
import { RawPlan } from 'components/context/plan/planContext.types';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { TariffName } from 'components/tariff/components/name/TariffName';
import { TaxPercentage } from 'components/taxPercentage/TaxPercentage';
import { PlanVariant } from 'hooks/usePlans';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { TDefaultParams } from 'router/routes';

import { messages } from './InvitationOrderSummary.messages';
import style from './style.module.scss';
import { EuropeOnly } from '../../../../components/context/plan/discriminators/region/EuropeOnly';
import { NorthAmericaOnly } from '../../../../components/context/plan/discriminators/region/NorthAmericaOnly';
import { messages as baseFeeMessages } from '../../../../components/manage/overviewFlyout/overview/baseFee/baseFee.messages';
// "Temporarily" use disclaimer messages, as currently we can't translate new text before release
import { messages as disclaimerMessages } from '../../../../components/tariff/components/disclaimer/disclaimer.messages';

interface IProps {
  selectedPlan?: RawPlan;
  isRedesign?: boolean;
  isExpandable?: boolean;
}

const InvitationOrderSummary: React.FC<IProps> = observer(
  ({ selectedPlan, isRedesign, isExpandable }) => {
    const { vin } = useParams<TDefaultParams>();
    const [isExpanded, setIsExpanded] = useState(false);
    const intl = useIntl();
    const context = useContext(planContext);
    const { inclusivePeriodUntil } = useContext(vehicleContext);
    const selectedPlanTax = selectedPlan?.options[context.marketplace]?.find(
      (singleOption) => singleOption.pricingModel.taxRate,
    )?.pricingModel.taxRate;
    const isInclusivePlan =
      selectedPlan &&
      [
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(selectedPlan?.variant);

    const baseFee = context?.baseFee || 0;
    const numberBaseFee = Number(baseFee.toString().replace(/[^0-9.-]+/g, ''));
    return (
      <>
        {isRedesign ? (
          <div className={style.orderSummaryHeader}>
            <PText size="x-large">
              <FormattedMessage {...messages.orderSummaryTitle} />
            </PText>

            {isExpandable ? (
              <PButtonPure
                icon={isExpanded ? 'minus' : 'plus'}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <FormattedMessage
                  {...messages[
                    isExpanded
                      ? 'togleIsOpenButtonLabelHide'
                      : 'togleIsOpenButtonLabelShow'
                  ]}
                />
              </PButtonPure>
            ) : null}
          </div>
        ) : (
          <PHeading size="x-large" className={`${style.subTitle} ${style.pt4}`}>
            <FormattedMessage {...messages.orderSummaryTitle} />
          </PHeading>
        )}

        <div
          className={`${isExpandable && !isExpanded ? style.hiddenContent : ''}`}
        >
          {isRedesign ? null : (
            <>
              {numberBaseFee > 0 ? (
                <>
                  <PText className={style.pt3} size="large" weight="semi-bold">
                    <FormattedMessage
                      {...messages.orderSummaryHeadingMonthly}
                    />
                  </PText>
                  <PText size="small" weight="regular">
                    <FormattedMessage
                      {...messages.orderSummaryDisclaimerMonthly}
                    />
                  </PText>
                </>
              ) : (
                <>
                  <PText className={style.pt3} size="large" weight="semi-bold">
                    <FormattedMessage {...messages.orderSummaryHeading} />
                  </PText>
                  <PText size="small" weight="regular">
                    <FormattedMessage {...messages.orderSummaryDisclaimer} />
                  </PText>
                </>
              )}
            </>
          )}

          {isRedesign ? (
            <div className={style.additionalDataWrapper}>
              <div>
                <div className={style.titleText}>
                  <TariffName />
                </div>

                <div className={style.textContainer}>
                  <span className={style.textSubtitle}>
                    {context.getTariffNameWithVin(vin!)}
                  </span>
                </div>

                <div className={style.textContainer}>
                  <span className={style.textSubtitle}>
                    {intl.formatMessage(messages.startDateText)}
                  </span>
                  :{' '}
                  {intl.formatDate(new Date(), {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </div>

                {inclusivePeriodUntil ? (
                  <div className={style.textContainer}>
                    <span className={style.textSubtitle}>
                      {intl.formatMessage(
                        messages.expirationOfInclusivePeriodText,
                      )}
                    </span>
                    :{' '}
                    {intl.formatDate(new Date(inclusivePeriodUntil), {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </div>
                ) : null}
              </div>

              <div className={style.baseFeeWrapper}>
                <PText size="medium">{baseFee}</PText>
                <PText>
                  {intl.formatMessage(baseFeeMessages.monthlyTitle)}
                </PText>
              </div>
            </div>
          ) : (
            <>
              <PText size="x-small" className={style.pt3} weight="bold">
                {intl.formatMessage(messages.vin, {
                  vin,
                })}
              </PText>

              <div className={style.orderLine}>
                <PText size="x-small" weight="regular">
                  {selectedPlan && <TariffName />}
                </PText>

                <PText size="x-small" weight="regular">
                  {baseFee}
                </PText>
              </div>
            </>
          )}

          <PDivider color="contrast-low" className={style.pt3} />
        </div>

        <div className={`${style.orderLine} ${style.pt3}`}>
          <PText size="medium" weight="bold">
            <FormattedMessage {...messages.totalToPay} />
          </PText>

          <div>
            <PText align="right" size="medium" weight="bold">
              {baseFee}{' '}
            </PText>

            {isRedesign ? (
              <PText align="right" size="small" weight="bold">
                {intl.formatMessage(baseFeeMessages.monthlyTitle)}
              </PText>
            ) : null}

            <PText
              color="neutral-contrast-high"
              size="x-small"
              className={style.pt1}
              weight="regular"
            >
              <NorthAmericaOnly>
                <FormattedMessage {...disclaimerMessages.excluded} />
              </NorthAmericaOnly>

              <EuropeOnly>
                {selectedPlanTax && !isInclusivePlan ? (
                  <TaxPercentage taxPercentage={selectedPlanTax} />
                ) : (
                  <FormattedMessage {...disclaimerMessages.included} />
                )}
              </EuropeOnly>
            </PText>
          </div>
        </div>
      </>
    );
  },
);

export default InvitationOrderSummary;
