import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'invitationBanner.title',
    defaultMessage:
      'Power your journey - Enjoy discounted charging and no monthly fee until {endDate}',
    description: 'Title of the invitation Banner',
  },
  exclusiveTitle: {
    id: 'invitationBanner.exclusiveTitle',
    defaultMessage:
      'Power your journey - Choose the Porsche plan that works for you!',
    description: 'Title of the invitation Banner',
  },
  subTitle1: {
    id: 'invitationBanner.subTitle1',
    defaultMessage:
      'Access to over 850,000 charging points in 25 countries in Europe.',
    description: 'First subtitle of the invitation Banner',
  },
  subTitle2: {
    id: 'invitationBanner.subTitle2',
    defaultMessage: 'Centralized billing: across countries, across CPOs',
    description: 'Second subtitle of the invitation Banner',
  },
  subTitle3: {
    id: 'invitationBanner.subTitle3',
    defaultMessage:
      'Transparent prices per charging class (AC / DC / Preferred) - no differentiation between CPOs within a class.',
    description: 'Third subtitle of the invitation Banner',
  },
});
