import { observer } from 'mobx-react-lite';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { upgradeContext } from './UpgradeContextProvider';
import { UpgradePlansContext } from './UpgradePlansContext';
import { Region } from '../plan/planContext.types';
import { subscriptionContext } from '../subscription/SubscriptionContext';
import {
  Authentication,
  useAuthentication,
} from '../../../hooks/useAuthentication/useAuthentication';

interface UpgradePlansContextProviderProps {
  children?: ReactNode;
}

const defaultContext = new UpgradePlansContext({
  dummy: true,
  auth: {} as Authentication,
  region: Region.EU,
  planVariants: [],
  marketplace: '',
});

export const upgradePlansContext =
  createContext<UpgradePlansContext>(defaultContext);

/**
 * Provides and rebuilds {@link UpgradePlansContext}
 */
export const UpgradePlansContextProvider: React.FunctionComponent<UpgradePlansContextProviderProps> =
  observer((props) => {
    const [context, setContext] = useState<UpgradePlansContext>(defaultContext);
    const upgrade = useContext(upgradeContext);
    const subscription = useContext(subscriptionContext);
    const auth = useAuthentication();

    useEffect(() => {
      if (!subscription.subscription) {
        return;
      }
      setContext(
        new UpgradePlansContext({
          dummy: false,
          region: subscription.subscription.region,
          planVariants: upgrade.planVariants,
          marketplace: subscription.subscription.country,
          auth,
        }),
      );
    }, [auth, subscription.subscription, upgrade.planVariants]);

    return (
      <upgradePlansContext.Provider value={context}>
        {props.children}
      </upgradePlansContext.Provider>
    );
  });
