import {
  PCheckboxWrapper,
  PHeading,
  PRadioButtonWrapper,
  PText,
} from '@porsche-design-system/components-react';
import { RawPlan } from 'components/context/plan/planContext.types';
import { PlanContextProvider } from 'components/context/plan/PlanContextProvider';
import { Disclaimer } from 'components/tariff/components/disclaimer/Disclaimer';
import { Region } from 'hooks/usePlans';
import { observer } from 'mobx-react-lite';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import { usePlan } from 'pages/invitation/usePlan';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Plan from './Plan';
import { messages } from './planCard.messages';
import PlanCardsSkeleton from './Skeleton/PlanCardsSkeleton';
import style from './style.module.scss';

interface IProps {
  onPlanChanged: (planId: string, plan: RawPlan) => void;
  isRedesign?: boolean;
  currentPlanId?: string;
}

const PlanSelection: React.FC<IProps> = observer(
  ({ onPlanChanged, isRedesign, currentPlanId }) => {
    const onPlanChangedRef = React.useRef(onPlanChanged);
    const { isLoading, plans, marketplace, locale, region, planVariants } =
      usePlan(onPlanChangedRef.current);
    const isEuRegion = region === Region.EU;

    const [checkedPlanId, setCheckedPlanId] = useState<string>(
      currentPlanId || '',
    );

    const handlePlanChange = (planId: string) => {
      setCheckedPlanId(planId);
      onPlanChanged(planId, plans.find((plan) => plan.id === planId)!);
    };

    useEffect(() => {
      if (plans && plans.length > 0 && !checkedPlanId) {
        setCheckedPlanId(plans[0].id);
      }
    }, [plans]);

    if (isLoading) {
      return <PlanCardsSkeleton />;
    }

    return !isRedesign ? (
      <>
        <PHeading size="x-large" className={style.subTitle}>
          <FormattedMessage {...messages.tariffActivation} />
        </PHeading>
        <PText className={style.pt2} size="small" weight="regular">
          <FormattedMessage {...messages.tariffActivationSubtitle} />
        </PText>
        <div className={style.plantList}>
          {plans &&
            plans.map((plan, index) => (
              <label className={style.planCardLabel} key={plan.id}>
                <PRadioButtonWrapper>
                  <input
                    type="radio"
                    name="chargingTariffId"
                    defaultChecked={index === 0}
                    value={plan.variant}
                    onChange={() => onPlanChanged(plan.id, plan)}
                  />
                </PRadioButtonWrapper>
                <PlanContextProvider
                  plan={plan}
                  marketplace={marketplace}
                  locale={locale}
                >
                  <Plan
                    rawPlan={plan}
                    selected={index === 0 && plans.length === 2}
                  />
                </PlanContextProvider>
              </label>
            ))}
        </div>
        {isEuRegion ? (
          <AdditionalDisclaimer planVariants={planVariants} />
        ) : null}
        {plans && (
          <PText
            className={style.pt2}
            size="x-small"
            weight="thin"
            color="neutral-contrast-medium"
          >
            <PlanContextProvider
              plan={plans[0]}
              marketplace={marketplace}
              locale={locale}
            >
              <Disclaimer />
            </PlanContextProvider>
          </PText>
        )}
      </>
    ) : (
      <>
        <div className={style.plansList}>
          {plans &&
            plans.map((plan, index) => (
              <div
                className={`${style.cardContainer} ${checkedPlanId === plan.id ? style.active : ''}`}
                key={plan.id}
                onClick={() => handlePlanChange(plan.id)}
              >
                <PCheckboxWrapper>
                  <input
                    type="checkbox"
                    name="chargingTariffId"
                    checked={checkedPlanId === plan.id}
                    value={plan.variant}
                  />
                </PCheckboxWrapper>
                <PlanContextProvider
                  plan={plan}
                  marketplace={marketplace}
                  locale={locale}
                >
                  <Plan
                    rawPlan={plan}
                    selected={index === 0 && plans.length === 2}
                    isRedesign={isRedesign}
                  />
                </PlanContextProvider>
              </div>
            ))}
        </div>
        {isEuRegion ? (
          <AdditionalDisclaimer planVariants={planVariants} />
        ) : null}

        {plans && (
          <PText
            className={style.pt2}
            size="x-small"
            weight="thin"
            color="neutral-contrast-medium"
          >
            <PlanContextProvider
              plan={plans[0]}
              marketplace={marketplace}
              locale={locale}
            >
              <Disclaimer />
            </PlanContextProvider>
          </PText>
        )}
      </>
    );
  },
);

export default PlanSelection;
