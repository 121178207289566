import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './inclusivePeriod.messages';
import { PlanContext, planContext } from '../../../context/plan/PlanContext';
import {
  PlanVariant,
  RawPlan,
  Region,
} from '../../../context/plan/planContext.types';
import { vehicleContext } from '../../../context/vehicle/VehicleContextProvider';

type InclusivePeriodProps = {
  v2PremiumPlan?: RawPlan | null;
};

/**
 * Display an inclusive period disclaimer as well as the follow-up price.
 */
export const InclusivePeriod: React.FunctionComponent<InclusivePeriodProps> =
  observer(({ v2PremiumPlan }) => {
    const context = useContext(planContext);
    const vehicle = useContext(vehicleContext);
    const intl = useIntl();

    const amount = useMemo(() => {
      if (!v2PremiumPlan) {
        return null;
      }

      const planContext = new PlanContext({
        plan: v2PremiumPlan,
        locale: intl.locale,
        marketplace: context.marketplace,
        dummy: false,
      });

      return planContext.baseFee;
    }, [context, intl, v2PremiumPlan]);

    if (
      ![
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(context.variant) ||
      !amount
    ) {
      return null;
    }

    const date = intl.formatDate(vehicle.inclusivePeriodUntil || new Date(), {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    });

    const isNarInclusivePremium =
      context.variant === PlanVariant.V2_PREMIUM_INCLUSIVE &&
      (context.region === Region.US || context.region === Region.CA);
    const isEuTaycanPlan =
      context.variant === PlanVariant.V1_PREMIUM_INCLUSIVE &&
      context.region === Region.EU;

    if (isNarInclusivePremium) {
      return <FormattedMessage {...messages.narText} values={{ date }} />;
    }

    if (isEuTaycanPlan) {
      return <FormattedMessage {...messages.euV1Inclusive} values={{ date }} />;
    }

    return (
      <FormattedMessage
        {...messages.text}
        values={{ date, amount: amount || 'LOADING...' }}
      />
    );
  });
