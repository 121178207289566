import { PCrest } from '@porsche-design-system/components-react';
import React, { ReactNode, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import style from './basicLayout.module.scss';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

interface BasicLayoutProps {
  children: ReactNode;
}

export const BasicLayout: React.FunctionComponent<BasicLayoutProps> = (
  props,
) => {
  const { locale } = useParams();
  const { pathname } = useLocation();
  const isMobile = useMemo(() => pathname.startsWith('/m/'), [pathname]);

  return (
    <div className={style.root} data-testid={'basicLayout'}>
      {isMobile ? (
        <PCrest className={style.crest} />
      ) : (
        <Header locale={locale!} />
      )}
      {props.children}
      <Footer locale={locale!} />
    </div>
  );
};
