import {
  PorscheDesignSystemProvider,
  PToast,
} from '@porsche-design-system/components-react';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BackendClient } from './backend-client';
import { CookieConsent } from './components/misc/cookieConsent/CookieConsent';
import { FullStory } from './components/misc/fullStory/FullStory';
import { NewRelic } from './components/misc/newRelic/NewRelic';
import { PreloadImages } from './components/misc/PreloadImages';
import { Router } from './router';

import './index.scss';

BackendClient.OpenAPI.BASE = process.env.REACT_APP_BASE_API_URL || '';
configure({ enforceActions: 'never' });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <NewRelic>
      <FullStory />
      <PreloadImages />
      <CookieConsent />
      <PorscheDesignSystemProvider prefix="my-charging">
        <Router />
        <PToast />
      </PorscheDesignSystemProvider>
    </NewRelic>
  </React.StrictMode>,
);
