import { Bullet } from 'components/slider/Bullet';
import style from 'components/slider/slider.module.scss';
import React, { FC } from 'react';

interface SliderProps {
  /**
   * Current active bullet, starts counting at 0
   */
  activeIndex: number;
  /**
   * Attach links to each bullet, following index ordering. <br/>
   * Active bullets have their link disabled.
   */
  links: (string | null)[];
}

/**
 * Slider for navigation/display of paging information to the user. <br/>
 * Fully animated.
 * @param props
 * @constructor
 */
export const Slider: FC<SliderProps> = React.memo((props) => {
  return (
    <div className={style.container}>
      {props.links.map((link, key) => {
        const active = key === props.activeIndex;

        return <Bullet active={active} link={link} key={key} />;
      })}
    </div>
  );
});
