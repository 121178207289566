import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

import {
  BasePricing,
  Blocking,
  BlockingType,
  ChargePointClass,
  FeeType,
  Option,
  PlanVariant,
  Price,
  RawPlan,
  Region,
  UsagePricing,
} from './planContext.types';
import { getTariffName } from '../../../utils/getTariffName';

enum Pricing {
  Net,
  Gross,
}

interface PlanContextProps {
  plan: RawPlan;
  marketplace: string;
  locale: string;
  dummy?: boolean;
  newPlan?: RawPlan | null;
}

/**
 * Utilities for displaying plan prices
 */
export class PlanContext {
  private readonly plan: RawPlan;
  public readonly marketplace: string;
  public readonly locale: string;
  public readonly name: string;
  public readonly currencyCode: string;
  public readonly dummy: boolean;
  private readonly newPlan?: RawPlan | null;

  constructor(props: PlanContextProps) {
    makeAutoObservable(this);

    this.dummy = props.dummy || false;
    this.plan = props.plan;
    this.marketplace = props.marketplace.toUpperCase();
    this.locale = props.locale;
    this.name = props.plan.name;
    this.currencyCode = this.generateCurrencyCode();
    this.newPlan = props.newPlan;
  }

  public get region(): Region {
    return this.plan.region;
  }

  public get variant(): PlanVariant {
    return this.plan.variant;
  }

  private get options(): Option[] {
    if (!this.plan.options[this.marketplace]) {
      return [];
    }
    return this.plan.options[this.marketplace];
  }

  public resolvePlanBaseFee(plan: RawPlan): string {
    if (plan.variant === PlanVariant.V1_PREMIUM_INCLUSIVE) {
      return this.formatCurrency(0);
    }

    if (!plan?.options[this.marketplace]) {
      return this.formatCurrency(0);
    }

    const base = plan.options[this.marketplace].find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (!base) {
      return this.formatCurrency(0);
    }

    if (this.pricing === Pricing.Gross) {
      return this.formatCurrency(base.pricingModel.price.grossAmount.decimal);
    }

    return this.formatCurrency(base.pricingModel.price.netAmount.decimal);
  }

  /**
   * 1.0 + 2.0 Returns current base fee, formatted with currency
   */
  public get baseFee(): string {
    return this.resolvePlanBaseFee(this.plan);
  }

  public get newPlanBaseFee(): string {
    return this.resolvePlanBaseFee(this.newPlan!);
  }

  public get priceUpdateBaseFee(): string {
    if (!this.newPlan) {
      return '';
    }

    return `${this.baseFee} → ${this.newPlanBaseFee}`;
  }

  /**
   *  NA V2_PREMIUM only. Returns yearly base fee, formatted with currency
   */
  public get yearlyBaseFee(): string {
    if (
      this.variant !== PlanVariant.V2_PREMIUM ||
      (this.marketplace !== Region.US && this.region !== Region.CA)
    ) {
      return '';
    }

    const base = this.options.find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (!base) {
      return this.formatCurrency(0);
    }

    return this.formatCurrency(
      (base.pricingModel.price.grossAmount.cents * 12) / 100,
    );
  }

  public get newPlanYearlyBaseFee(): string {
    if (!this.newPlan) {
      return '';
    }

    const base = this.newPlan.options[this.marketplace].find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (!base) {
      return this.formatCurrency(0);
    }

    return this.formatCurrency(
      (base.pricingModel.price.grossAmount.cents * 12) / 100,
    );
  }

  /**
   * Format a number to a string with currency appended (or prefixed)
   * @param amount
   */
  private formatCurrency(amount: number): string {
    return this.currency.format(amount);
  }

  /**
   * Creates and returns an Intl.NumberFormat for currency
   * @constructor
   */
  private get currency(): Intl.NumberFormat {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
    });
  }

  /**
   * 1.0 + 2.0 Generates currency code for current plan
   */
  private generateCurrencyCode(): string {
    if (this.region === Region.CA) {
      return 'CAD';
    }

    if (this.region === Region.US) {
      return 'USD';
    }

    // Take currency code of base price if existing
    const base = this.options.find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (base) {
      return base.pricingModel.price.grossAmount.currency;
    }

    // Just try our best at this point
    for (const item of this.options) {
      const unknown = item as {
        pricingModel?: {
          priceTiers?: { price: Price }[];
        };
      };

      if (
        unknown.pricingModel?.priceTiers &&
        unknown.pricingModel.priceTiers[0]
      ) {
        return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
      }
    }

    return 'EUR';
  }

  /**
   * 2.0 + 1.0, Resolves and returns blocking cost and params
   * @param chargePointClass
   * @private
   */
  public resolveBlocking(chargePointClass: ChargePointClass): null | Blocking {
    const base = this.options.find((item) => {
      return (
        item.feeType === FeeType.BLOCKING &&
        item.chargePointClass === chargePointClass
      );
    }) as UsagePricing | undefined;

    const newBase = this.newPlan
      ? (this.newPlan.options[this.marketplace]?.find((item) => {
          return (
            item.feeType === FeeType.BLOCKING &&
            item.chargePointClass === chargePointClass
          );
        }) as UsagePricing | undefined)
      : null;

    if (!base) {
      return null;
    }

    if (base.pricingModel.priceTiers.length === 2) {
      const planPrice = this.currency.format(
        this.resolvePricing(base.pricingModel.priceTiers[1]),
      );
      const newPlanPrice = newBase
        ? this.currency.format(
            this.resolvePricing(newBase.pricingModel.priceTiers[1]),
          )
        : null;

      const price = newPlanPrice ? `${planPrice} → ${newPlanPrice}` : planPrice;

      return {
        type: BlockingType.ByTime,
        time: base.pricingModel.priceTiers[0].quantity,
        price,
      };
    }
    const kwMap: Partial<Record<ChargePointClass, number>> = {
      [ChargePointClass.DC_50_KW]: 50,
      [ChargePointClass.DC_150_KW]: 150,
      [ChargePointClass.HPC_350_KW]: 350,
    };

    if (kwMap[chargePointClass]) {
      const planPrice = this.currency.format(
        this.resolvePricing(base.pricingModel.priceTiers[0]),
      );
      const newPlanPrice = newBase
        ? this.currency.format(
            this.resolvePricing(newBase.pricingModel.priceTiers[0]),
          )
        : null;
      const price = newPlanPrice ? `${planPrice} → ${newPlanPrice}` : planPrice;

      return {
        type: BlockingType.ByPower,
        kw: kwMap[chargePointClass]!,
        price,
      };
    }

    const planPrice = this.currency.format(
      this.resolvePricing(base.pricingModel.priceTiers[0]),
    );
    const newPlanPrice = newBase
      ? this.currency.format(
          this.resolvePricing(newBase.pricingModel.priceTiers[0]),
        )
      : null;
    const price = newPlanPrice ? `${planPrice} → ${newPlanPrice}` : planPrice;

    return {
      type: BlockingType.Instant,
      price,
    };
  }

  /**
   * Resolves and returns the energy price <br/>
   * Use FeeType.CHARGING for 2.0 tariffs <br/>
   * Use FeeType.ENERGY for 1.0 tariffs
   * @param chargePointClass
   * @private
   */
  public resolveEnergyPrice(chargePointClass: ChargePointClass): string | null {
    const planPrice = this.resolvePlanEnergyPrice(chargePointClass, this.plan);
    const newPlanPrice = this.newPlan
      ? this.resolvePlanEnergyPrice(chargePointClass, this.newPlan)
      : null;

    return newPlanPrice ? `${planPrice} → ${newPlanPrice}` : planPrice;
  }

  public resolvePlanEnergyPrice(
    chargePointClass: ChargePointClass,
    plan: RawPlan,
  ): string | null {
    const base = plan.options[this.marketplace]?.find((item) => {
      if (item.feeType !== FeeType.ENERGY) {
        return undefined;
      }
      return item.chargePointClass === chargePointClass;
    }) as UsagePricing | undefined;

    if (!base) {
      return null;
    }

    return this.currency.format(
      this.resolvePricing(base.pricingModel.priceTiers[0]),
    );
  }

  public resolveNewPlanEnergyPrice(
    chargePointClass: ChargePointClass,
  ): string | null {
    if (!this.newPlan) {
      return null;
    }

    const base = this.newPlan.options[this.marketplace].find((item) => {
      if (item.feeType !== FeeType.ENERGY) {
        return undefined;
      }
      return item.chargePointClass === chargePointClass;
    }) as UsagePricing | undefined;

    if (!base) {
      return null;
    }

    return this.currency.format(
      this.resolvePricing(base.pricingModel.priceTiers[0]),
    );
  }

  /**
   * Resolves priceTier object to a simple number
   * @param priceTier
   * @private
   */
  private resolvePricing(priceTier: { price: Price }): number {
    if (this.pricing === Pricing.Gross) {
      return priceTier.price.grossAmount.decimal;
    }
    return priceTier.price.netAmount.decimal;
  }

  /**
   * Return currently used pricing method <br/>
   * Calculated value
   * @private
   */
  private get pricing(): Pricing {
    if (this.marketplace === 'US' || this.marketplace === 'CA') {
      return Pricing.Net;
    }

    return Pricing.Gross;
  }

  /**
   * Shorthand for {@link getTariffName}
   * @param short
   */
  public getTariffName = (short?: boolean) => {
    return getTariffName(this.variant, this.marketplace, short);
  };

  public getTariffNameWithVin = (vin: string) => {
    return `${getTariffName(this.variant, this.marketplace)} (${vin})`;
  };
}

export const dummyPlanContext = new PlanContext({
  marketplace: 'US',
  locale: 'en-US',
  dummy: true,
  plan: {
    id: '',
    name: '',
    options: {},
    region: Region.EU,
    variant: PlanVariant.V1_PREMIUM_INCLUSIVE,
    pcsVersion: '',
  },
});

export const planContext = createContext<PlanContext>(dummyPlanContext);
