import {
  PButton,
  PButtonPure,
  PHeading,
  PInlineNotification,
  PRadioButtonWrapper,
  PText,
  PTextareaWrapper,
} from '@porsche-design-system/components-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './terminate.messages';
import style from './terminate.module.scss';
import { CustomerService } from '../customerService/CustomerService';
import { PlanVariant } from '../../../context/plan/planContext.types';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';

enum TerminationReason {
  SELLING_VEHICLE = 'SELLING_VEHICLE',
  EXPENSIVE = 'EXPENSIVE',
  BASE_FEES = 'BASE_FEE',
  OTHER = 'OTHER',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  CHARGING_PROBLEMS = 'CHARGING_PROBLEMS',
  BETTER_OFFER = 'BETTER_OFFER',
}

type TerminateProps = {
  isOpen: boolean;
  setIsOpen: (openValue: boolean) => void;
};

export const Terminate: React.FunctionComponent<TerminateProps> = observer(
  ({ isOpen, setIsOpen }) => {
    const [error, setError] = useState(false);
    const [locked, setLocked] = useState(false);
    const [success, setSuccess] = useState(false);
    const [additionalFeedback, setAdditionalFeedback] = useState('');
    const [terminationReason, setTerminationReason] =
      useState<TerminationReason>();
    const intl = useIntl();
    const context = useContext(subscriptionContext);

    /**
     * Opens the termination flyout
     */
    const onOpen = useCallback(() => {
      setError(false);
      setIsOpen(true);
    }, [setIsOpen]);

    /**
     * Closes the termination flyout
     */
    const onClose = useCallback(() => {
      if (locked) {
        return;
      }
      setIsOpen(false);
    }, [locked, setIsOpen]);

    /**
     * Terminates the contract
     */
    const onTerminate = useCallback(async () => {
      if (!context.subscription) {
        return;
      }
      setLocked(true);
      const result = await context.terminate(
        terminationReason,
        additionalFeedback,
      );

      if (result) {
        setError(false);
        setLocked(false);
        setSuccess(true);
        return;
      }

      setError(true);
      setLocked(false);
    }, [context, terminationReason, additionalFeedback]);

    const terminationReasons = [
      {
        value: TerminationReason.SELLING_VEHICLE,
        label: intl.formatMessage(messages.reasonSellingVehicle),
      },
      {
        value: TerminationReason.EXPENSIVE,
        label: intl.formatMessage(messages.reasonExpensive),
      },
      {
        value: TerminationReason.BASE_FEES,
        label: intl.formatMessage(messages.reasonBaseFee),
      },
      {
        value: TerminationReason.INFRASTRUCTURE,
        label: intl.formatMessage(messages.reasonInfrastructure),
      },
      {
        value: TerminationReason.CHARGING_PROBLEMS,
        label: intl.formatMessage(messages.reasonChargingProblems),
      },
      {
        value: TerminationReason.BETTER_OFFER,
        label: intl.formatMessage(messages.reasonBetterOffer),
      },
      {
        value: TerminationReason.OTHER,
        label: intl.formatMessage(messages.reasonOther),
      },
    ];

    const handleAdditionalFeedback = (
      event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      setAdditionalFeedback(event.target.value);
    };

    /**
     * Display either a success message or an action button <br/>
     * Except V1 because it's already pre-terminated
     */
    const content = useMemo(() => {
      if (success) {
        return (
          <PText>
            <FormattedMessage {...messages.success} />
          </PText>
        );
      }

      if (
        context.subscription?.currentOrLastPhase.plan.variant !==
          PlanVariant.V1_PREMIUM_INCLUSIVE &&
        context.subscription?.isTerminated
      ) {
        return (
          <PText>
            <FormattedMessage {...messages.success} />
          </PText>
        );
      }

      return (
        <>
          <div className={style.radioGroup}>
            {terminationReasons.map((reason) => (
              <PRadioButtonWrapper
                key={reason.value}
                label={reason.label}
                hideLabel={false}
              >
                <input
                  type="radio"
                  name="termination-reason"
                  value={reason.value}
                  onChange={() => setTerminationReason(reason.value)}
                />
              </PRadioButtonWrapper>
            ))}
          </div>
          <PTextareaWrapper
            label={intl.formatMessage(messages.additionalFeedbackLabel)}
            hideLabel={false}
          >
            <textarea
              name="additionalFeedback"
              onChange={handleAdditionalFeedback}
              maxLength={1000}
            >
              {additionalFeedback}
            </textarea>
          </PTextareaWrapper>
          <PText>
            <FormattedMessage {...messages.confirm} />
          </PText>
          <div className={style.actions}>
            <PButton
              loading={locked}
              onClick={onTerminate}
              disabled={!terminationReason}
            >
              <FormattedMessage {...messages.action} />
            </PButton>
            <PButton variant={'secondary'} onClick={onClose} disabled={locked}>
              <FormattedMessage {...messages.cancel} />
            </PButton>
          </div>
        </>
      );
    }, [
      success,
      context.subscription?.currentOrLastPhase.plan.variant,
      context.subscription?.isTerminated,
      locked,
      onTerminate,
      onClose,
      terminationReason,
    ]);

    /**
     * Conditionally display an error message
     */
    const errorComponent = useMemo(() => {
      if (!error) {
        return null;
      }
      return (
        <PInlineNotification
          heading={intl.formatMessage(messages.errorTitle)}
          description={intl.formatMessage(messages.errorText)}
          state={'error'}
          className={style.error}
          dismissButton={false}
        />
      );
    }, [error, intl]);

    /**
     * Disallows termination if already terminated <br/>
     * Except V1 because it's already pre-terminated
     */
    const disabled = useMemo(() => {
      if (!context.subscription || context.subscription.isExpired || success) {
        return true;
      }
      if (
        context.subscription?.currentOrLastPhase.plan.variant ===
        PlanVariant.V1_PREMIUM_INCLUSIVE
      ) {
        return false;
      }
      return context.subscription.isTerminated;
    }, [context.subscription, success]);

    return (
      <>
        {isOpen ? (
          <>
            {errorComponent}

            <div className={style.table}>
              <PHeading size={'large'}>
                <FormattedMessage
                  {...messages[success ? 'terminateSuccessTitle' : 'terminate']}
                />
              </PHeading>

              {success ? null : (
                <PText>
                  <FormattedMessage {...messages.terminateSubtitle} />
                </PText>
              )}
              {content}
            </div>

            <CustomerService />
          </>
        ) : (
          <PButtonPure icon={'disable'} onClick={onOpen} disabled={disabled}>
            <FormattedMessage {...messages.terminate} />
          </PButtonPure>
        )}
      </>
    );
  },
);
