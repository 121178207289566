import React from 'react';

import style from './style.module.scss';

const AddressSkeleton = () => {
  return (
    <>
      <div className={style.skeletonHeader}>
        <div className={style.skeletonBox} />
        <div className={style.skeletonBox} />
      </div>
      <div className={style.skeletonHeader}>
        <div className={style.skeletonBox} />
        <div className={style.skeletonBox} />
      </div>
    </>
  );
};

export default AddressSkeleton;
