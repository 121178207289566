import { PText } from '@porsche-design-system/components-react';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { messages } from './duration.messages';
import { subscriptionContext } from '../../../../context/subscription/SubscriptionContext';

export const Duration: React.FunctionComponent = observer((_props) => {
  const context = useContext(subscriptionContext);
  const intl = useIntl();

  if (!context.subscription) {
    return null;
  }

  /**
   * IsTerminated OR has no follow up phase and an end date
   */
  if (context.subscription.isTerminated && context.subscription.endDate) {
    return (
      <div data-testid={'duration'}>
        <PText>
          <FormattedMessage {...messages.endDateTitle} />
        </PText>
        <PText weight={'bold'} align={'right'}>
          <FormattedMessage
            {...messages.endDateText}
            values={{
              date: intl.formatDate(context.subscription.endDate, {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              }),
            }}
          />
        </PText>
      </div>
    );
  }

  // Hide section if we're within the inclusive period
  if (context.subscription.inclusivePeriodActiveUntil) {
    return null;
  }

  return (
    <div data-testid={'duration'}>
      <PText>
        <FormattedMessage {...messages.autoRenewTitle} />
      </PText>
      <PText weight={'bold'} align={'right'}>
        <FormattedDate
          value={
            context.subscription.renewalDate || context.subscription.endDate
          }
          month={'long'}
          year={'numeric'}
          day={'numeric'}
        />
      </PText>
    </div>
  );
});
