import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  premium: {
    defaultMessage: 'Porsche Charging Premium',
    id: 'manage.overview.tile.title.premium',
  },
  basic: {
    defaultMessage: 'Porsche Charging Basic',
    id: 'manage.overview.tile.title.basic',
  },
  legacy: {
    defaultMessage: 'Porsche Charging Service',
    id: 'manage.overview.tile.title.legacy',
  },
  terminated: {
    defaultMessage: 'Contract terminated',
    id: 'manage.overview.tile.tag.terminated',
  },
  terminationProgress: {
    defaultMessage: 'Subscription termination in progress',
    id: 'manage.overview.tile.tag.terminationProgress',
  },
  monthlyFeeTitle: {
    defaultMessage: 'Monthly fee',
    id: 'manage.overview.tile.tag.monthlyFeeTitle',
  },
  contractEndingTitle: {
    defaultMessage: 'Contract ending',
    id: 'manage.overview.tile.tag.contractEndingTitle',
  },
  inclusiveSubtext: {
    defaultMessage: 'Available free of charge until {inclusiveEndDate}.',
    id: 'manage.overview.tile.tag.inclusiveSubtext',
  },
  exclusiveSubtext: {
    defaultMessage:
      'Your contract remains active with no set end date—feel free to enjoy your ongoing service.',
    id: 'manage.overview.tile.tag.exclusiveSubtext',
  },
  terminationInProgressSubtext: {
    defaultMessage:
      'The termination will take effect on {terminationDate}. You can reactivate  your subscription at any time.',
    id: 'manage.overview.tile.tag.terminationInProgressSubtext',
  },
  suspendedSubtext: {
    defaultMessage:
      'Your subscription has been suspended due to missed payments.',
    id: 'manage.overview.tile.tag.suspendedSubtext',
  },
  suspendedNoticationText: {
    defaultMessage: 'Subscription suspended.',
    id: 'manage.overview.tile.tag.suspendedNoticationText',
  },
  terminatedSubtext: {
    defaultMessage: 'Your subscription has been terminated.',
    id: 'manage.overview.tile.tag.terminatedSubtext',
  },
  tariffDetailsLinkText: {
    defaultMessage: 'Tariff details',
    id: 'manage.overview.tile.tag.tariffDetailsLinkText',
  },
});
