import style from 'components/layout/frosted/frosted.module.scss';
import { FC, ReactNode } from 'react';

interface FrostedItemProps {
  children: ReactNode;
  stylingClass?: string;
}

export const FrostedItem: FC<FrostedItemProps> = (props) => {
  return (
    <div className={props.stylingClass || style.item}>{props.children}</div>
  );
};
