import { logErrorToNewRelic } from 'components/misc/newRelic/NewRelic';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuthentication } from './useAuthentication/useAuthentication';

export const useCanActivateCheck = () => {
  // canActivate -> null initial value means that the check has not been performed yet, false if failed, true if passed
  const [canActivate, setCanActivate] = useState<boolean | null>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [suspendedVin, setSuspendedVin] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuthentication();
  const { vin, marketplace, ciamId } = useParams();

  const checkCanActivate = useCallback(async () => {
    if (!auth || !marketplace || !vin) {
      return;
    }

    const queryParams = new URLSearchParams({
      country: marketplace?.toUpperCase(),
      vin: vin,
    });

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/validate?${queryParams.toString()}`,
        {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'apikey': auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        setCanActivate(true);
        setLoading(false);
        return;
      }

      const data = await response.json();
      setCanActivate(data.allowed);
      setErrorCode(data.error?.key);
      setSuspendedVin(data.payload?.vin);

      if (data.error?.key) {
        logErrorToNewRelic(new Error('Activation pre check failed'), {
          ciamId,
          vin,
          marketplace,
          key: data.error.key,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setCanActivate(true);
      setLoading(false);
    }
  }, [auth, marketplace, vin, logErrorToNewRelic, ciamId]);

  if (auth && marketplace && vin && canActivate === null && !loading) {
    checkCanActivate();
  }

  return { canActivate, errorCode, isLoading: loading, suspendedVin };
};
