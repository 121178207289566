import { PHeading, PText } from '@porsche-design-system/components-react';
import { BlankLayout } from 'components/layout/blank/BlankLayout';
import { FC, useEffect, useState } from 'react';

import style from './licenses.module.scss';

const cleanupText = (text: string): string => {
  return (
    text
      // Remove project title
      .replace(/^=+\s*Project Name: .+\s*=+$/gm, '')
      // Replace the unnecessary text with "Dependencies" and wrap it in +++ in order to be displayed as title
      .replace(
        'The following list contains attribution notices, among them, where applicable, attribution notices of various relevant modules.',
        '+++\nTitle: Dependencies\n+++\n',
      )
      // Wrap french main title
      .replace(
        /(Dispositions générales sur les logiciels libres et offre écrite)/g,
        '+++\nTitle: $1\n+++\n',
      )
      // Wrap english main title
      .replace(
        /={10,}\n\n(General Open Source Provisions and written offer)\n\n={10,}/g,
        '+++\nTitle: $1\n+++\n',
      )
      // Remove "================..." lines
      .replace(/={10,}/g, '')
      // Replace repeated sequence like "°°°°°°°°°°°°°°°°°°..." with "+++"
      .replace(/°{10,}/g, '+++')
      // Replace repeated sequence like "------------------..." with "+++"
      .replace(/-{10,}/g, '+++')
      // Replace repeated sequence like "******************..." with "+++"
      .replace(/\*{10,}/g, '+++')
      // Prefix package names with "Package:" in order to be displayed as title
      .replace(/\+\+\+\n(.*)\n\+\+\+\n#?/g, '+++\nSubtitle: $1\n+++\n')
      // Replace multiple blank lines with just one blank line
      .replace(/\n{3,}/g, '\n\n')
  );
};

export const LicensesPage: FC = () => {
  const [licenses, setLicenses] = useState('');

  useEffect(() => {
    fetch('/NOTICE.txt')
      .then((response) => response.text())
      .then((text) => setLicenses(cleanupText(text)));
  }, []);

  if (!licenses) return null;

  return (
    <BlankLayout>
      {licenses.split('\n+++').map((paragraph, index) => {
        const matchesTitle = /Title: (.*)/.exec(paragraph);
        const matchesSubtitle = /Subtitle: (.*)/.exec(paragraph);
        if (matchesTitle) {
          return (
            <PHeading tag="h3" size="large" className={style.title} key={index}>
              {matchesTitle[1]}
            </PHeading>
          );
        }
        if (matchesSubtitle) {
          return (
            <PHeading
              tag="h4"
              size="medium"
              className={style.title}
              key={index}
            >
              {matchesSubtitle[1]}
            </PHeading>
          );
        }
        return (
          <PText className={style.text} key={index}>
            {paragraph}
          </PText>
        );
      })}
    </BlankLayout>
  );
};
