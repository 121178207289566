import {
  PButtonPure,
  PHeading,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { PlanContext } from 'components/context/plan/PlanContext';
import { PlanVariant } from 'hooks/usePlans';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { messages } from './overviewTile.messages';
import style from './overviewTile.module.scss';
import { PriceChangeTag } from '../priceChangeTag/PriceChangeTag';
import { BasicOnly } from '../../../../components/context/plan/discriminators/tariff/BasicOnly';
import { LegacyOnly } from '../../../../components/context/plan/discriminators/tariff/LegacyOnly';
import { PremiumOnly } from '../../../../components/context/plan/discriminators/tariff/PremiumOnly';
import { subscriptionContext } from '../../../../components/context/subscription/SubscriptionContext';

type OverviewTileProps = { isDisabled?: boolean };

export const OverviewTile: React.FunctionComponent<OverviewTileProps> =
  observer(({ isDisabled = false }) => {
    const { marketplace, locale } = useParams();
    const context = useContext(subscriptionContext);
    const navigate = useNavigate();

    const openOverview = useCallback(() => {
      navigate('./overview');
    }, [navigate]);

    const getReadableDate = (date: string) => {
      return Intl.DateTimeFormat('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(new Date(date));
    };

    const contentToRenderMap = useMemo(() => {
      if (!context.subscription || !locale || !marketplace) {
        return null;
      }

      const emptyContentMap = {
        subtext: null,
        tag: null,
        monthlyFee: null,
        contractEnding: null,
      };
      const {
        isTerminated,
        isSuspended,
        endDate,
        currentOrLastPhase: { plan },
        inclusivePeriodActiveUntil,
        country,
      } = context.subscription;
      const planContext = new PlanContext({
        plan,
        locale,
        marketplace: country,
        dummy: false,
      });

      const isExplicitlyTerminated = isTerminated && endDate;
      const endDateObj = endDate ? new Date(endDate) : null;
      const currentDateObj = new Date();
      // Now + 30 days
      const futureDateObj = new Date(
        currentDateObj.getTime() + 30 * 24 * 60 * 60 * 1000,
      );
      const isV1Inclusive =
        planContext.variant === PlanVariant.V1_PREMIUM_INCLUSIVE;
      const isInclusive =
        isV1Inclusive ||
        planContext.variant === PlanVariant.V2_PREMIUM_INCLUSIVE;

      const isTerminationEndDateWithin30Days =
        !isV1Inclusive &&
        isExplicitlyTerminated &&
        endDateObj &&
        endDateObj < futureDateObj &&
        endDateObj > currentDateObj;

      if (isTerminationEndDateWithin30Days) {
        return {
          ...emptyContentMap,
          tag: (
            <PTag
              color={'notification-warning-soft'}
              icon={'information'}
              className={style.tag}
            >
              <FormattedMessage {...messages.terminationProgress} />
            </PTag>
          ),
          monthlyFee: planContext.baseFee,
          subtext: (
            <FormattedMessage
              {...messages.terminationInProgressSubtext}
              values={{ terminationDate: getReadableDate(endDate) }}
            />
          ),
        };
      } else if (isExplicitlyTerminated && context.subscription.isExpired) {
        return {
          ...emptyContentMap,
          subtext: <FormattedMessage {...messages.terminatedSubtext} />,
        };
      } else if (isSuspended) {
        return {
          ...emptyContentMap,
          subtext: <FormattedMessage {...messages.suspendedSubtext} />,
          monthlyFee: planContext.baseFee,
          tag: (
            <PTag
              color={'notification-error-soft'}
              icon={'information'}
              className={style.tag}
            >
              <FormattedMessage {...messages.suspendedNoticationText} />
            </PTag>
          ),
        };
      }

      return {
        monthlyFee: planContext.baseFee,
        subtext:
          inclusivePeriodActiveUntil && isInclusive ? (
            <FormattedMessage
              {...messages.inclusiveSubtext}
              values={{
                inclusiveEndDate: getReadableDate(inclusivePeriodActiveUntil),
              }}
            />
          ) : (
            <FormattedMessage {...messages.exclusiveSubtext} />
          ),
        contractEnding:
          inclusivePeriodActiveUntil && isInclusive
            ? getReadableDate(inclusivePeriodActiveUntil)
            : null,
        tag: (
          <PriceChangeTag
            className={style.tag}
            subscription={context.subscription}
          />
        ),
      };
    }, [context.subscription, locale, marketplace]);

    return (
      <div className={isDisabled ? style.rootDisabled : style.root}>
        <div className={style.tile} onClick={openOverview}>
          <div className={style.container}>
            <div className={style.columnContainer}>
              <div className={style.titleWithTagContainer}>
                <PText
                  weight="bold"
                  size={{ base: 'medium' }}
                  color={isDisabled ? 'contrast-medium' : 'primary'}
                >
                  <PremiumOnly>
                    <FormattedMessage {...messages.premium} />
                  </PremiumOnly>
                  <BasicOnly>
                    <FormattedMessage {...messages.basic} />
                  </BasicOnly>
                  <LegacyOnly>
                    <FormattedMessage {...messages.legacy} />
                  </LegacyOnly>
                </PText>

                {contentToRenderMap?.tag}
              </div>

              {contentToRenderMap?.monthlyFee ? (
                <div className={style.subtitleContainer}>
                  <PHeading size="small">
                    <FormattedMessage {...messages.monthlyFeeTitle} />
                  </PHeading>

                  <PText size="small">: {contentToRenderMap.monthlyFee}</PText>
                </div>
              ) : null}

              {contentToRenderMap?.contractEnding ? (
                <div className={style.subtitleContainer}>
                  <PHeading size="small">
                    <FormattedMessage {...messages.contractEndingTitle} />
                  </PHeading>

                  <PText size="small">
                    : {contentToRenderMap.contractEnding}
                  </PText>
                </div>
              ) : null}

              {contentToRenderMap?.tag ? contentToRenderMap.tag : null}

              {contentToRenderMap?.subtext ? (
                <PText
                  size="x-small"
                  color="contrast-medium"
                  className={style.subtext}
                >
                  {contentToRenderMap.subtext}
                </PText>
              ) : null}
            </div>

            <div className={style.buttonContainer}>
              {isDisabled ? null : (
                <PButtonPure
                  icon="information"
                  size="small"
                  hideLabel={{ base: true, m: true, l: false }}
                >
                  <FormattedMessage {...messages.tariffDetailsLinkText} />
                </PButtonPure>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });
