import {
  PInlineNotification,
  PText,
} from '@porsche-design-system/components-react';
import { EuropeOnly } from 'components/context/plan/discriminators/region/EuropeOnly';
import { NorthAmericaOnly } from 'components/context/plan/discriminators/region/NorthAmericaOnly';
import { TermsAndConditions } from 'components/tac/TermsAndConditions';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleChecked } from 'utils/handleCheckedInput';

import style from '../../invitation.module.scss';
import { messages } from '../../invitationPage.messages';

interface IProps {
  handleCanSubmitChange: (canSubmit: boolean) => void;
}

const ToCCheckboxes: React.FC<IProps> = ({ handleCanSubmitChange }) => {
  const intl = useIntl();

  const [isActive, setIsActive] = useState(true);
  const onDismiss = () => setIsActive(false);

  const [hasAcceptedTOC, setHasAcceptedTOC] = useState(false);
  const [hasAcceptedRevocationPeriod, setHasAcceptedRevocationPeriod] =
    useState(false);

  const canSubmit = useMemo(() => {
    return hasAcceptedRevocationPeriod && hasAcceptedTOC;
  }, [hasAcceptedRevocationPeriod, hasAcceptedTOC]);

  useEffect(() => {
    handleCanSubmitChange(canSubmit);
  }, [handleCanSubmitChange, canSubmit]);

  return (
    <>
      {isActive && (
        <>
          <EuropeOnly>
            <PInlineNotification
              heading={intl.formatMessage(messages.notificationHeader)}
              description={intl.formatMessage(messages.notificationBody)}
              onDismiss={onDismiss}
            />
          </EuropeOnly>
          <NorthAmericaOnly>
            <PInlineNotification
              heading={intl.formatMessage(messages.notificationHeader)}
              description={intl.formatMessage(
                messages.notificationBodyNorthAmerica,
              )}
              onDismiss={onDismiss}
            />
          </NorthAmericaOnly>
        </>
      )}
      <PText className={style.pt3}>
        <FormattedMessage {...messages.legalNotice} />
      </PText>
      <div className={style.toCSection}>
        <TermsAndConditions
          name="hasAcceptedTOC"
          checked={hasAcceptedTOC}
          revocationPeriodChecked={hasAcceptedRevocationPeriod}
          onRevocationPeriodChange={handleChecked(
            setHasAcceptedRevocationPeriod,
          )}
          onChange={handleChecked(setHasAcceptedTOC)}
        />
      </div>
    </>
  );
};

export default ToCCheckboxes;
