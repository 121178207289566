import {
  PButtonPure,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { useRoutes } from 'hooks/useRoutes';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'router/routes';

import { messages } from './reactivationTag.messages';
import styles from './reactivationTag.module.scss';

type ReactivationTagProps = {
  endDate: string;
  closeRedirectRoute?: string;
};

export const ReactivationTag: FC<ReactivationTagProps> = ({
  endDate,
  closeRedirectRoute,
}) => {
  const navigate = useNavigate();
  const generateRoute = useRoutes();

  const searchParams = closeRedirectRoute
    ? new URLSearchParams({ backUrl: closeRedirectRoute })
    : '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <PIcon name="warning" color="notification-warning" />
      </div>

      <div className={styles.textWrapper}>
        <PText weight="bold">
          <FormattedMessage {...messages.title} />
        </PText>

        <PText>
          <FormattedMessage {...messages.text} values={{ date: endDate }} />
        </PText>
      </div>

      <div className={styles.buttonWrapper}>
        <PButtonPure
          icon="refresh"
          onClick={() =>
            navigate(
              `${generateRoute(Routes.Reactivation)}${searchParams ? `?${searchParams}` : ''}`,
            )
          }
        >
          <FormattedMessage {...messages.reactivationButtonLabel} />
        </PButtonPure>
      </div>
    </div>
  );
};
