import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  plugAndCharge: {
    defaultMessage: 'Plug & Charge and app authentication',
    id: 'flyout.overview.membership.plugAndCharge',
  },
  basicPricingUs: {
    defaultMessage: 'Guest or Electrify America Pass membership prices***',
    id: 'flyout.overview.membership.basicPricingUs',
  },
  basicPricingCa: {
    defaultMessage: 'Guest or Electrify Canada Pass membership prices***',
    id: 'flyout.overview.membership.basicPricingCa',
  },
  premiumPricingUs: {
    defaultMessage:
      'Cheaper charging prices* with Electrify America Pass+ membership',
    id: 'flyout.overview.membership.premiumPricingUs',
  },
  premiumPricingCa: {
    defaultMessage:
      'Cheaper charging prices* with Electrify Canada Pass+ membership',
    id: 'flyout.overview.membership.premiumPricingCa',
  },
  saveUpTo: {
    defaultMessage: 'Save up to 25% on charging',
    id: 'flyout.overview.membership.saveUpTo',
  },
  electrifyAmerica: {
    defaultMessage: 'Electrify America',
    id: 'flyout.overview.membership.electrifyAmerica',
  },
  chargePointAndPartners: {
    defaultMessage: 'ChargePoint and roaming partner networks',
    id: 'flyout.overview.membership.chargePointAndPartners',
  },
  appAuthentication: {
    defaultMessage: 'App authentication',
    id: 'flyout.overview.disclaimer.appAuthentication',
  },
  noMonthlyFee: {
    defaultMessage: 'No monthly fee',
    id: 'flyout.overview.membership.noMonthlyFee',
  },
  inclusiveAc: {
    defaultMessage: 'AC (≤ 7 kW)',
    id: 'flyout.overview.membership.inclusiveAc',
  },
  inclusiveSubtitle: {
    defaultMessage: 'additional fees apply afterwards',
    id: 'flyout.overview.membership.inclusiveSubtitle',
  },
  inclusiveDc: {
    defaultMessage: 'DC (≤ 350 kW)',
    id: 'flyout.overview.membership.inclusiveDc',
  },
  inclusiveGrace: {
    defaultMessage: 'Grace Period',
    id: 'flyout.overview.membership.inclusiveGrace',
  },
  inclusiveIdle: {
    defaultMessage: 'Idle fee',
    id: 'flyout.overview.membership.inclusiveIdle',
  },
  inclusiveIdleSubtitle: {
    defaultMessage: 'Depending on the charging station',
    id: 'flyout.overview.membership.inclusiveIdleSubtitle',
  },
  inclusiveChargePoint: {
    defaultMessage: 'ChargePoint',
    id: 'flyout.overview.membership.inclusiveChargePoint',
  },
  inclusiveChargePointSubtitle: {
    defaultMessage: 'Complimentary access',
    id: 'flyout.overview.membership.inclusiveChargePointSubtitle',
  },
  inclusiveChargePointSubContent: {
    defaultMessage:
      'Complimentary access to ChargePoint and Affiliate partners. To activate this complimentary access, a ChargePoint account needs to be created in connection to your Porsche Charging Service account - this must be done via the My Porsche App.***',
    id: 'flyout.overview.membership.inclusiveChargePointSubContent',
  },
  chargePointRegistered: {
    defaultMessage: 'Access available',
    id: 'flyout.overview.membership.chargePointRegistered',
  },
  chargePointRegisteredSubContent: {
    defaultMessage: 'ChargePoint account successfully created',
    id: 'flyout.overview.membership.chargePointRegisteredSubContent',
  },
  chargePointActivationPending: {
    defaultMessage: 'Account activation pending',
    id: 'flyout.overview.membership.chargePointActivationPending',
  },
  chargePointNotAvailable: {
    defaultMessage: 'Not available',
    id: 'flyout.overview.membership.chargePointNotAvailable',
  },
  chargePointCTA: {
    defaultMessage: 'Please create your ChargePoint account in My Porsche App',
    id: 'flyout.overview.membership.chargePointCTA',
  },
  inclusiveTaxDisclaimer: {
    defaultMessage: 'Taxes and other fees are excluded from all prices.',
    id: 'flyout.overview.membership.inclusiveTaxDisclaimer',
  },
  inclusivePricesDisclaimer: {
    defaultMessage:
      'See detailed prices for at charging station or in the My Porsche app.',
    id: 'flyout.overview.membership.inclusivePricesDisclaimer',
  },
  inclusiveChargePointDisclaimer: {
    defaultMessage:
      'This access does not accrue additional costs through the inclusive plan. All vehicle charging via the ChargePoint Network and affiliate partner operators will be billed to your account.***',
    id: 'flyout.overview.membership.inclusiveChargePointDisclaimer',
  },
  inclusiveChargePointDisclaimerDisclaimer: {
    defaultMessage:
      'Access to the ChargePoint Network and affiliate partners is only available within the inclusive period or the Porsche Charging Service Premium subscription.',
    id: 'flyout.overview.membership.inclusiveChargePointDisclaimerDisclaimer',
  },
  inclusiveFlatrate: {
    defaultMessage: '{amount} min flatrate',
    id: 'flyout.overview.membership.inclusiveFlatrate',
  },
  inclusiveGraceText: {
    defaultMessage: '{amount} min',
    id: 'flyout.overview.membership.inclusiveGraceText',
  },
});
