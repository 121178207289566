import React, { ReactNode, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';

import translations from '../../../intl/translations';

interface LocaleContextProviderProps {
  children?: ReactNode;
}

export const defaultLocale = 'en-GB';

/**
 * Provides the INTL context and sets the locale out of the url parameters
 * @param props
 * @constructor
 */
export const LocaleContextProvider: React.FunctionComponent<
  LocaleContextProviderProps
> = (props) => {
  const params = useParams<{ locale: string }>();
  const locale = useMemo(() => {
    if (!params.locale) {
      return defaultLocale;
    }

    if (params.locale.match(/^[a-z]{2}$/)) {
      return params.locale;
    }

    if (params.locale.match(/^[a-z]{2}-[A-Z]{2}$/)) {
      return params.locale;
    }

    return defaultLocale;
  }, [params.locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={translations[locale || defaultLocale]}
    >
      {props.children}
    </IntlProvider>
  );
};
