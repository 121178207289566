import { makeAutoObservable } from 'mobx';

import { Authentication } from '../../../hooks/useAuthentication/useAuthentication';
import { NetworkStatus } from '../../../hooks/useCombinedNetworking/useCombinedNetworking';
import { PlanVariant } from '../../../pages/tariff/select/SelectPage';

type EntitlementObject = {
  type: 'inclusive' | 'exclusive';
  planVariants: PlanVariant[];
};

export enum Entitlement {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE',
  NotEntitled = 'NOT_ENTITLED',
}

export enum Ownership {
  /**
   * Ownership has not been verified
   */
  Unconfirmed = 'UNCONFIRMED',
  /**
   * User's profile data contains this vehicle, not necessary "owner"
   */
  Owner = 'OWNER',
  /**
   * User's profile data does not contain this vehicle
   */
  External = 'EXTERNAL',
}

interface MyData {
  vehicles: { vin: string; ownershipType?: string }[];
  emails: { email: string; isStandard: boolean }[];
}

export interface VehicleContextProps {
  vin: string;
  dummy: boolean;
  marketplace: string;
  auth: Authentication;
}

export class VehicleContext {
  public network: NetworkStatus = NetworkStatus.Loading;

  private readonly auth: Authentication;
  public readonly vin: string;
  public readonly marketplace: string;
  /**
   * Entitlement status of the vehicle.
   * It's best to use {@link ExclusiveOnly} or {@link InclusiveOnly} instead of accessing this value directly
   */
  public entitlement: Entitlement = Entitlement.Exclusive;
  public ownership: Ownership = Ownership.Unconfirmed;
  public ownershipEmail = '';

  entitlementObject: EntitlementObject | null = null;
  vehicleName: string | null = null;
  entitledUntil: Date | null = null;
  inclusivePeriodUntil: Date | null = null;
  requestId: string | null = null;
  errorCode: string | null = null;

  constructor(props: VehicleContextProps) {
    makeAutoObservable(this);
    this.auth = props.auth;
    this.vin = props.vin;
    this.marketplace = props.marketplace;

    if (props.dummy) {
      return;
    }
    void this.fetchData();
  }

  private fetchData = async () => {
    try {
      const entitlementResponse = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/vehicles/${this.vin}/entitlement?country=${this.marketplace}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!entitlementResponse.ok) {
        const result = await entitlementResponse.json();
        this.entitlement = Entitlement.NotEntitled;
        this.network = NetworkStatus.Success;
        this.errorCode = result.errorCode;
        this.requestId = result.requestId;
        return;
      }

      const entitlement = await entitlementResponse.json();
      this.entitlementObject = entitlement;
      this.vehicleName = entitlement.vehicleName;

      if (entitlement.entitledUntil) {
        this.entitledUntil = new Date(entitlement.entitledUntil);
      }

      if (entitlement.inclusivePeriodUntil) {
        this.inclusivePeriodUntil = new Date(entitlement.inclusivePeriodUntil);
      }

      this.entitlement = entitlement.type as Entitlement;
      this.network = NetworkStatus.Success;
    } catch (error: unknown) {
      this.network = NetworkStatus.Error;
      this.errorCode = 'NET';
    }
  };

  /**
   * Calls MyProfileData to check ownership of current vehicle <br/>
   * Currently gets called conditionally on sus behavior
   */
  public checkOwnership = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_MY_PROFILE_API_URL!, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.auth.token}`,
          'apikey': this.auth.apiKey,
          'Content-Type': 'application/json',
        },
      });

      const result = (await response.json()) as MyData;
      const vehicle = result.vehicles.find((item) => {
        return item.vin === this.vin && item.ownershipType !== 'secondary';
      });

      const email = result.emails.find((item) => {
        return item.isStandard;
      });
      this.ownershipEmail = email?.email || result.emails[0].email;

      if (vehicle === undefined) {
        this.ownership = Ownership.External;
        return;
      }

      this.ownership = Ownership.Owner;
      return;
    } catch (e: unknown) {
      console.error(e);
      return;
    }
  };
}
