import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { observer } from 'mobx-react-lite';
import { FailureComponent } from 'pages/confirmation/failure/failureComponent/FailureComponent';
import React, { ReactNode, useContext } from 'react';

import LoadingVehicleSpinner from './LoadingVehicleSpinner';
import { Entitlement } from '../VehicleContext';
import { NetworkStatus } from '../../../../hooks/useCombinedNetworking/useCombinedNetworking';

interface VehicleBarrierProps {
  children?: ReactNode;
}

export const VehicleBarrier: React.FunctionComponent<VehicleBarrierProps> =
  observer((props) => {
    const context = useContext(vehicleContext);

    if (context.network === NetworkStatus.Loading) {
      return <LoadingVehicleSpinner />;
    }

    if (context.network === NetworkStatus.Error) {
      return <FailureComponent errorCodeOverride="NET" />;
    }

    if (context.entitlement === Entitlement.NotEntitled) {
      return (
        <FailureComponent
          errorCodeOverride={context.errorCode || undefined}
          requestIdOverride={context.requestId || undefined}
        />
      );
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  });
