import {
  PButton,
  PSpinner,
  PText,
} from '@porsche-design-system/components-react';
import background from 'media/backgroundSuccess.png';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { messages } from './successPage.messages';
import style from './successPage.module.scss';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import { FrostedHeading } from '../../../components/layout/frosted/FrostedHeading';
import { FrostedItem } from '../../../components/layout/frosted/FrostedItem';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';

export const SuccessPage: React.FunctionComponent = observer(() => {
  const intl = useIntl();
  const context = useContext(subscriptionContext);

  const date = useMemo(() => {
    if (context.subscription?.nextPossibleEffectiveDate) {
      return intl.formatDate(
        new Date(context.subscription.nextPossibleEffectiveDate),
        {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        },
      );
    }

    return <PSpinner className={style.spinner} size={'inherit'} />;
  }, [context.subscription?.nextPossibleEffectiveDate, intl]);

  return (
    <FrostedLayout background={background} className={style.panel}>
      <FrostedHeading>
        <FormattedMessage {...messages.title} />
      </FrostedHeading>
      <FrostedItem>
        <PText size={'large'}>
          <FormattedMessage {...messages.text} values={{ date }} />
        </PText>
      </FrostedItem>
      <Link to={'./../../manage/contract/overview'} className={style.fullWidth}>
        <PButton className={style.fullWidth}>
          <FormattedMessage {...messages.action} />
        </PButton>
      </Link>
    </FrostedLayout>
  );
});
