import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  included: {
    defaultMessage: 'All displayed prices include taxes.',
    id: 'flyout.overview.disclaimer.included',
  },
  excluded: {
    defaultMessage: 'Taxes and other fees are not included.',
    id: 'flyout.overview.disclaimer.excluded',
  },
  excludedYearly: {
    defaultMessage:
      'Porsche Charging Service Premium is {yearly} annual subscription billed monthly at {monthly}. Taxes and other fees are not included.',
    id: 'flyout.overview.disclaimer.excludedYearly',
  },
  seeInApp: {
    defaultMessage:
      'See detailed prices for at charging station or in the My Porsche app.',
    id: 'flyout.overview.disclaimer.seeInApp',
  },
});
