import { makeAutoObservable } from 'mobx';
import { createContext, FC, ReactNode, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface DeviceContextProviderProps {
  children?: ReactNode;
}

export class DeviceContext {
  isMobile: boolean;
  returnUrl?: string;
  isReturnUrlWeb?: boolean;

  constructor(isMobile: boolean, returnUrl?: string) {
    makeAutoObservable(this);
    this.isMobile = isMobile;
    this.returnUrl = returnUrl;
  }
}

export const deviceContext = createContext<DeviceContext>(
  new DeviceContext(false, undefined),
);

export const DeviceContextProvider: FC<DeviceContextProviderProps> = (
  props,
) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isMobile = useMemo(() => pathname.startsWith('/m/'), [pathname]);
  const returnUrl = useMemo(
    () => searchParams.get('returnUrl') ?? undefined,
    [searchParams],
  );
  const isReturnUrlWeb = useMemo(
    () => !!returnUrl?.startsWith('http') || !returnUrl,
    [returnUrl],
  );

  return (
    <deviceContext.Provider value={{ isMobile, returnUrl, isReturnUrlWeb }}>
      {props.children}
    </deviceContext.Provider>
  );
};
