import { CustomerType } from 'components/context/plan/planContext.types';
import { logErrorToNewRelic } from 'components/misc/newRelic/NewRelic';
import { defaultLocale } from 'hooks/useParams';
import {
  getNormalizedLocale,
  TAddressChangeEventPayload,
} from 'pages/activation/components/AddressWidget';
import { triggerApplePayTransaction } from 'pages/activation/components/PaymentWidget/paymentRequests';
import { PaymentData } from 'pages/activation/components/PaymentWidget/usePaymentWidget';
import { usePayPal } from 'pages/activation/components/PaymentWidget/usePayPal';
import { isVatCheckRequired } from 'pages/activation/useCheckout';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchApi } from 'utils/fetchApi';

import { subscriptionContext } from '../../../../components/context/subscription/SubscriptionContext';
import { useAuthentication } from '../../../../hooks/useAuthentication/useAuthentication';

export type CreditCard = {
  displayName?: string;
  holderName?: string;
  preferred?: boolean;
  preferredFrom?: string;
  expiryDate?: string;
  fundingSource?: string;
  addedAt?: string;
  id?: string;
  typeDisplayName?: string;
  shopperEmail?: string;
};

export type CreditCradType = {
  details: CreditCard[];
  displayName: string;
  psp: string;
  type: string;
};

export type PaymentMethod = {
  method: string;
  types: CreditCradType[];
};

type SubscriptionUpdateProps = {
  invoiceAddressId?: number;
  paymentReference?: string;
};

export const useSubscriptionManagement = ({
  savedCurrency,
}: {
  savedCurrency: string | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { locale, marketplace } = useParams();
  const authentication = useAuthentication();
  const { triggerPayPalInitialTransaction, savedPaymentWidgetPayPalMethodId } =
    usePayPal({
      apiKey: authentication.apiKey,
      token: authentication.token,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [editableFieldsOpenStatus, setEditableFieldsOpenStatus] = useState<{
    [key: string]: boolean;
  }>({
    ...(searchParams.get('fieldName')
      ? { [searchParams.get('fieldName') || '']: true }
      : {}),
  });
  const [businessVerificationData, setBusinessVerificationData] = useState<{
    legalName: string;
    vat: string;
  }>({ legalName: '', vat: '' });
  const [requiresBusinessVerification, setRequiresBusinessVerification] =
    useState(false);
  const [defaultAddressId, setDefaultAddressId] = useState('');
  const [defaultPaymentData, setDefaultPaymentData] = useState<PaymentData>(
    {} as PaymentData,
  );
  const { subscription, fetchData } = useContext(subscriptionContext);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isAddressAllowed, setIsAddressAllowed] = useState(true);
  const [isCorrectAddressType, setIsCorrectAddressType] = useState(true);
  const [applePayToken, setApplePayToken] = useState<null | string>(null);
  const [addressSufix, setAddressSuffix] = useState<null | string>(null);
  const [subscriptionAddressId, setSubscriptionAddressId] = useState<
    null | string
  >(null);

  const clearPaymentSearchParams = useCallback(() => {
    if (
      searchParams.get('errorActionCode') ||
      searchParams.get('status') ||
      searchParams.get('paymentMethodId') ||
      searchParams.get('paypal')
    ) {
      searchParams.delete('errorActionCode');
      searchParams.delete('status');
      searchParams.delete('paymentMethodId');
      searchParams.delete('paypal');
      setSearchParams(searchParams);
    }
  }, [setSearchParams, searchParams]);

  const fetchPaymentMethods = useCallback(async () => {
    const paymentMethodsResponse = await fetch(
      `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/mypaymentmethods/${marketplace}`,
      {
        method: 'POST',
        headers: {
          'apikey': authentication.apiKey,
          'Authorization': `Bearer ${authentication.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodTypes: ['creditcard', 'directdebit', 'redirect'],
          curency: 'EUR',
          channel: 'Web',
          basket: [{ assortment: 'CHARGING', amount: 0 }],
        }),
      },
    );

    const paymentMethods = await paymentMethodsResponse.json();
    setPaymentMethods(paymentMethods.paymentMethods);
  }, [authentication.token, authentication.apiKey, marketplace]);

  const activeCreditCard = useMemo(() => {
    if (!subscription || !paymentMethods) {
      return null;
    }

    const creditCards = paymentMethods.find(
      (paymentMethod: PaymentMethod) => paymentMethod.method === 'creditcard',
    );

    if (!creditCards) {
      return null;
    }

    const matchingCreditCard = creditCards.types.reduce(
      (acc: CreditCard, type: CreditCradType) => {
        const creditCardDetails = type.details?.find(
          (detail) => detail.id === subscription?.paymentReference,
        );

        if (creditCardDetails) {
          return { ...creditCardDetails, typeDisplayName: type.displayName };
        }

        return acc;
      },
      {},
    );

    if (matchingCreditCard.typeDisplayName) {
      return matchingCreditCard;
    }

    const redirectPaymentOptions = paymentMethods.find(
      (paymentMethod: PaymentMethod) => paymentMethod.method === 'redirect',
    );

    if (!redirectPaymentOptions) {
      return null;
    }

    const matchingRedirectPayment = redirectPaymentOptions.types.reduce(
      (acc: CreditCard, type: CreditCradType) => {
        const creditCardDetails = type.details?.find(
          (detail) => detail.id === subscription?.paymentReference,
        );

        if (creditCardDetails) {
          return { ...creditCardDetails, typeDisplayName: type.displayName };
        }

        return acc;
      },
      {},
    );

    return matchingRedirectPayment;
  }, [subscription, paymentMethods]);

  const fetchAddressById = useCallback(
    (addressId: string) =>
      fetch(
        `${process.env.REACT_APP_MY_PROFILE_API_URL}/addresses/${addressId}`,
        {
          headers: {
            apikey: authentication.apiKey,
            Authorization: `Bearer ${authentication.token}`,
          },
        },
      )
        .then((response) => response.json())
        .catch(() => ({})),
    [authentication.token, authentication.apiKey],
  );

  const handleAddressSufix = useCallback(async () => {
    try {
      const customerAddresses = await fetch(
        `${process.env.REACT_APP_MY_PROFILE_API_URL}/addresses`,
        {
          headers: {
            apikey: authentication.apiKey,
            Authorization: `Bearer ${authentication.token}`,
          },
        },
      );
      const customerAddressesArray = await customerAddresses.json();

      const matchingAddress = customerAddressesArray.find(
        (singleAddress: any) => {
          const { city, companyName1, houseNumber, postalCode } = singleAddress;
          const {
            company: subscriptionCompany,
            streetNo: subscriptionStreetNumber,
            zip: subscriptionZip,
            city: subscriptionCity,
          } = subscription?.invoiceAddress || {};

          return (
            city === subscriptionCity &&
            companyName1 === subscriptionCompany &&
            houseNumber === subscriptionStreetNumber &&
            postalCode === subscriptionZip
          );
        },
      );

      setSubscriptionAddressId(matchingAddress.addressId || null);
      setAddressSuffix(matchingAddress?.coName || null);
    } catch (error: unknown) {
      setAddressSuffix(null);
    }
  }, [authentication, subscription]);

  useEffect(() => {
    if (subscription?.invoiceAddress) {
      handleAddressSufix();
    }
  }, [handleAddressSufix, subscription]);

  const normalizedLocale = getNormalizedLocale(locale ?? defaultLocale);
  const updateAddress = useCallback(
    async (address: any, vatId: string, legalName: string) =>
      await fetch(
        `${process.env.REACT_APP_PORSCHE_PROFILES_API_URL}/${marketplace}/${normalizedLocale}/address/${address.addressId}`,
        {
          method: 'PUT',
          headers: {
            'apikey': authentication.apiKey,
            'Authorization': `Bearer ${authentication.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...address,
            vatId: vatId.replaceAll(/[^a-z0-9]/gi, ''),
            companyName1: legalName,
          }),
        },
      )
        .then((response) => response.json())
        .catch(() => ({})),
    [
      authentication.token,
      authentication.apiKey,
      marketplace,
      normalizedLocale,
    ],
  );

  const getInitialData = useCallback(async () => {
    try {
      setIsLoading(true);
      await fetchPaymentMethods();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPaymentMethods]);

  useEffect(() => {
    getInitialData();
  }, [authentication.token, getInitialData]);

  const handleCloseAllFields = useCallback(() => {
    setSearchParams();

    setEditableFieldsOpenStatus((prevState) => {
      const prevStateCopy = { ...prevState };

      Object.keys(prevStateCopy).forEach((field) => {
        prevStateCopy[field] = false;
      });

      return prevStateCopy;
    });
  }, [setSearchParams]);

  const handleOpenEditableField = useCallback(
    (fieldName: string) => {
      setDefaultAddressId('');
      setIsAddressAllowed(true);
      setIsCorrectAddressType(true);

      setSearchParams(new URLSearchParams({ fieldName }));

      setEditableFieldsOpenStatus((prevState) => {
        const prevStateCopy = { ...prevState };

        Object.keys(prevStateCopy).forEach((field) => {
          prevStateCopy[field] = false;
        });

        prevStateCopy[fieldName] = true;

        return prevStateCopy;
      });
    },
    [setSearchParams],
  );

  const handleAddressEdit = useCallback((isEditing: boolean) => {
    setIsEditingAddress(isEditing);
  }, []);

  const handleAddressChange = useCallback(
    (addressId: TAddressChangeEventPayload) => {
      setRequiresBusinessVerification(false);
      setBusinessVerificationData({ legalName: '', vat: '' });
      setDefaultAddressId(
        typeof addressId === 'string' ? addressId : addressId.selectedAddressId,
      );
    },
    [],
  );

  const handlePaymentChange = useCallback(
    (data: PaymentData) => {
      clearPaymentSearchParams();
      setDefaultPaymentData(data);
      setApplePayToken(null);
    },
    [clearPaymentSearchParams],
  );

  const updateSubscription = useCallback(
    async (updateProps: SubscriptionUpdateProps) => {
      try {
        const response = await fetchApi(
          `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${subscription?.id}`,
          authentication.token,
          {
            method: 'POST',
            body: JSON.stringify(updateProps),
          },
        );

        if (!(response.status >= 200 && response.status < 300)) {
          throw new Error('Unable to update subscription');
        }

        await fetchData();
        if (updateProps.paymentReference) {
          await fetchPaymentMethods();
        }
        handleCloseAllFields();
      } catch (error) {
        throw new Error('Unable to update subscirption');
      }
    },
    [
      subscription,
      fetchData,
      handleCloseAllFields,
      authentication,
      fetchPaymentMethods,
    ],
  );

  const handleBusinessVerificationDataChange = useCallback(
    (data: { key: string; value: string }) => {
      setBusinessVerificationData((currentBusinessVerificationData) => ({
        ...currentBusinessVerificationData,
        [data.key]: data.value,
      }));
    },
    [],
  );

  const handleAddressSubmit = useCallback(async () => {
    setIsAddressAllowed(true);
    setIsCorrectAddressType(true);

    if (!subscription?.invoiceAddress) {
      return;
    }

    let addressToSubmit = await fetchAddressById(defaultAddressId);

    if (
      addressToSubmit.country.toLowerCase() !==
      subscription?.country.toLowerCase()
    ) {
      setIsLoading(false);
      setIsAddressAllowed(false);
      logErrorToNewRelic(new Error('Invoice address country mismatch'), {
        ciamId: authentication.ciamId,
        marketplace,
        addressCountry: addressToSubmit.country,
      });
      throw new Error('Address is not in the marketplace');
    }

    if (isVatCheckRequired(addressToSubmit)) {
      const businessVerificationPayload = {
        country: addressToSubmit.country,
        legalName:
          businessVerificationData.legalName || addressToSubmit.companyName1,
        vat: businessVerificationData.vat || addressToSubmit.vatId,
      };

      const hasRequiredValuesForVerification =
        businessVerificationPayload.country &&
        businessVerificationPayload.legalName &&
        businessVerificationPayload.vat;

      setBusinessVerificationData(businessVerificationPayload);

      if (!hasRequiredValuesForVerification) {
        setRequiresBusinessVerification(true);
        throw new Error('Address requires businesss verification');
      }

      let isVerified = false;
      try {
        const result = await fetchApi(
          `${process.env.REACT_APP_BASE_API_URL}/vat/verification`,
          authentication.token,
          {
            method: 'POST',
            body: JSON.stringify(businessVerificationPayload),
          },
        );

        if (!result.ok) {
          logErrorToNewRelic(new Error('Business verification failed'), {
            ciamId: authentication.ciamId,
            vatId: businessVerificationData.vat,
          });
        }

        isVerified = result.ok && (await result.json());
      } catch (error) {
        isVerified = false;
        logErrorToNewRelic(error, {
          ciamId: authentication.ciamId,
          vatId: businessVerificationData.vat,
          additionalInfo: 'Business verification failed',
        });
      }

      if (!isVerified) {
        setRequiresBusinessVerification(true);
        throw new Error('Address requires businesss verification');
      }

      addressToSubmit = await updateAddress(
        addressToSubmit,
        businessVerificationPayload.vat,
        businessVerificationPayload.legalName,
      );
    }

    if (
      addressToSubmit.usage === 'work'
        ? ![CustomerType.B2B, CustomerType.AFFILIATED].includes(
            subscription.customerType,
          )
        : !(subscription.customerType === CustomerType.B2C)
    ) {
      setIsLoading(false);
      setIsCorrectAddressType(false);
      throw new Error('Address is not correct type');
    }

    setIsAddressAllowed(true);
    setIsCorrectAddressType(true);
    setIsSubmiting(true);
    try {
      await updateSubscription({
        invoiceAddressId: addressToSubmit.addressId,
      });
      setAddressSuffix(addressToSubmit?.coName || null);
    } finally {
      setIsSubmiting(false);
    }
  }, [
    authentication.token,
    subscription,
    fetchAddressById,
    defaultAddressId,
    updateSubscription,
    businessVerificationData,
    updateAddress,
  ]);

  const handlePaymentSubmit = useCallback(async () => {
    setIsSubmiting(true);

    try {
      await fetchPaymentMethods();

      if (
        defaultPaymentData.paymentMethodDetails.paymentMethod
          .typeDisplayName === 'PayPal'
      ) {
        if (
          !defaultPaymentData.paymentMethodDetails
            .strongCustomerAuthenticationData?.shopperEmail
        ) {
          return await triggerPayPalInitialTransaction();
        }

        return await updateSubscription({
          paymentReference: savedPaymentWidgetPayPalMethodId,
        });
      } else if (applePayToken) {
        try {
          const applePayTransaction = await triggerApplePayTransaction({
            apiKey: authentication.apiKey,
            token: authentication.token,
            ciamId: authentication.ciamId,
            marketplace: marketplace || 'de',
            applePayToken,
            userAgent: navigator.userAgent,
            currency: savedCurrency,
          });

          const applePaymentMethodId = applePayTransaction?.paymentMethodId;

          return await updateSubscription({
            paymentReference: applePaymentMethodId,
          });
        } catch (error) {
          console.error(
            'Something went wrong with Apple Pay Transaction',
            error,
          );
          throw new Error('Apple Pay transaction failed');
        }
      }

      await updateSubscription({
        paymentReference:
          defaultPaymentData.paymentMethodDetails.paymentMethod.id,
      });
    } catch (error: unknown) {
      throw new Error('Update failed');
    } finally {
      setIsSubmiting(false);
    }
  }, [
    defaultPaymentData,
    updateSubscription,
    savedPaymentWidgetPayPalMethodId,
    triggerPayPalInitialTransaction,
    fetchPaymentMethods,
    applePayToken,
    authentication,
    marketplace,
    savedCurrency,
  ]);

  const handleApplePayToken = useCallback((token: string) => {
    setApplePayToken(token);
  }, []);

  const isPayPalSelected = useMemo(() => {
    return !!activeCreditCard?.shopperEmail;
  }, [activeCreditCard]);

  const isInitialApplePaySelected = useMemo(() => {
    return (
      defaultPaymentData?.paymentMethodDetails?.paymentMethod.type ===
        'applepay' &&
      !defaultPaymentData?.paymentMethodDetails?.paymentMethod.id
    );
  }, [defaultPaymentData]);

  const paymentPreviewText = useMemo(() => {
    const prefix = activeCreditCard?.typeDisplayName;
    const suffix = activeCreditCard?.displayName
      ? `****${activeCreditCard?.displayName?.slice(-4)}`
      : activeCreditCard?.shopperEmail;

    return `${prefix} - ${suffix}`;
  }, [activeCreditCard]);

  return {
    activeCreditCard,
    apiKey: authentication.apiKey,
    businessVerificationData,
    defaultAddressId,
    defaultPaymentData,
    editableFieldsOpenStatus,
    handleAddressChange,
    handleAddressEdit,
    handleAddressSubmit,
    handleBusinessVerificationDataChange,
    handleCloseAllFields,
    handleOpenEditableField,
    handlePaymentChange,
    handlePaymentSubmit,
    isAddressAllowed,
    isCorrectAddressType,
    isEditingAddress,
    isLoading,
    isPayPalSelected,
    isSubmitting,
    paymentPreviewText,
    isInitialApplePaySelected,
    requiresBusinessVerification,
    handleApplePayToken,
    applePayToken,
    subscription,
    token: authentication.token,
    addressSuffix: addressSufix,
    subscriptionAddressId,
  };
};
