import { breakpointS } from '@porsche-design-system/components-react/styles';
import style from 'components/layout/frosted/frostedLayout.module.scss';
import { FC, ReactNode, useEffect, useState } from 'react';

import background from '../../../media/backgroundTariff.jpg';

interface FrostedProps {
  children: ReactNode;
  /**
   * Optional background, a default background will be provided
   */
  background?: string;
  /**
   * ClassName for the frosted panel
   */
  className?: string;
  shouldShowNewMobileDesign?: boolean;
}

/**
 * Crates a layout with a frosted panel for the content and a full size background image. <br/>
 * Note, background change gets animated if you don't lifecycle the component.
 * @param props
 * @constructor
 */
export const FrostedLayout: FC<FrostedProps> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobileWidthRedesignAllowed =
    props.shouldShowNewMobileDesign && width <= breakpointS;

  return (
    <div
      style={
        !isMobileWidthRedesignAllowed
          ? { backgroundImage: `url("${props.background || background}")` }
          : {}
      }
      className={
        isMobileWidthRedesignAllowed
          ? style.backgroundRedesign
          : style.background
      }
      data-testid={'frostedLayout'}
    >
      <div
        className={
          isMobileWidthRedesignAllowed ? style.layoutRedesign : style.layout
        }
      >
        <div className={`${style.panel} ${props.className}`}>
          {props.children}
        </div>
        <div className={style.spacing} />
      </div>
    </div>
  );
};
