import { PText } from '@porsche-design-system/components-react';
import style from 'components/layout/frosted/frosted.module.scss';
import { FC, ReactNode } from 'react';

interface FrostedHeadingProps {
  children: ReactNode;
}

export const FrostedHeading: FC<FrostedHeadingProps> = (props) => {
  return (
    <div className={style.header}>
      <PText size={'inherit'}>{props.children}</PText>
    </div>
  );
};
