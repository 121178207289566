import { PText } from '@porsche-design-system/components-react';
import { EuropeOnly } from 'components/context/plan/discriminators/region/EuropeOnly';
import { NorthAmericaOnly } from 'components/context/plan/discriminators/region/NorthAmericaOnly';
import { planContext } from 'components/context/plan/PlanContext';
import { Charging } from 'components/tariff/charging/Charging';
import { BaseFee } from 'components/tariff/components/baseFee/BaseFee';
import { InclusivePeriod } from 'components/tariff/components/inclusivePeriod/InclusivePeriod';
import { Membership } from 'components/tariff/components/membership/Membership';
import { TariffName } from 'components/tariff/components/name/TariffName';
import { PlanVariant, Region } from 'hooks/usePlans';
import { useSpecificPlan } from 'hooks/useSpecificPlan';
import React, { useContext } from 'react';

import { messages } from './planCard.messages';
import style from './style.module.scss';
import { RawPlan } from '../../../../components/context/plan/planContext.types';

interface IProps {
  selected: boolean;
  rawPlan: RawPlan;
  isRedesign?: boolean;
}

const Plan: React.FC<IProps> = ({ rawPlan, isRedesign }) => {
  const shouldPreventV2PremiumPlanSearch = ![
    PlanVariant.V1_PREMIUM_INCLUSIVE,
    PlanVariant.V2_PREMIUM_INCLUSIVE,
  ].includes(rawPlan.variant);
  const v2PremiumPlan = useSpecificPlan({
    planVariant: PlanVariant.V2_PREMIUM,
    shouldPreventPlanSearch: shouldPreventV2PremiumPlanSearch,
  });

  const isNarBasicPremium =
    rawPlan.variant === PlanVariant.V2_PREMIUM_INCLUSIVE;
  const context = useContext(planContext);

  const isNARegion = [Region.US, Region.CA].includes(context.region);

  return (
    <div>
      <div className={style.heading}>
        <div className={style.tariffTitle}>
          <PText weight="bold" size="large">
            <TariffName />
          </PText>
        </div>
        <PText className={style.pt1} weight="bold" size="medium">
          <BaseFee />
        </PText>
        <PText
          style={{
            maxWidth: isNARegion ? undefined : 480,
          }}
          color="contrast-medium"
          size="x-small"
        >
          <InclusivePeriod v2PremiumPlan={v2PremiumPlan} />
        </PText>
      </div>
      <div className={isRedesign ? style.pricesWrapper : style.priceContainer}>
        <NorthAmericaOnly>
          <Membership
            isInvitationProcess
            isNarBasicPremium={isNarBasicPremium}
          />
        </NorthAmericaOnly>
        <EuropeOnly>
          <Charging
            style={{
              simpleSegmentRoot: style.simpleSegmentRoot,
            }}
            messages={messages}
            components={{
              simpleSegmentSubText: {
                size: 'x-small',
              },
              simpleSegmentSubTitle: {
                size: 'x-small',
              },
            }}
          />
        </EuropeOnly>
      </div>
    </div>
  );
};

export default Plan;
