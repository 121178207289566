import {
  PButton,
  PButtonPure,
  PText,
} from '@porsche-design-system/components-react';
import { FC, ReactNode } from 'react';

import style from './invitationExpandableContent.module.scss';

type InvitationExpandableContentProps = {
  children: ReactNode;
  isOpen: boolean;
  step: number;
  closedContent: ReactNode;
  title: string;
  handleEditButtonClick: () => void;
  handleActionButtonClick: () => void;
  handleSecondaryActionButtonClick?: (() => void) | null;
  hideActionButton?: boolean;
  actionButtonText: string;
  secondaryActionButtonText?: string;
  isActionButtonDisabled?: boolean;
  shouldHideEditButton?: boolean;
  toggleOpenButtonLabel: string;
};

export const InvitationExpandableContent: FC<
  InvitationExpandableContentProps
> = ({
  children,
  isOpen,
  step,
  closedContent,
  title,
  handleEditButtonClick,
  hideActionButton = false,
  handleActionButtonClick,
  actionButtonText,
  isActionButtonDisabled = false,
  shouldHideEditButton = false,
  toggleOpenButtonLabel,
  handleSecondaryActionButtonClick,
  secondaryActionButtonText,
}) => {
  return (
    <div className={style.mainContainer}>
      <div className={`${style.alwaysVisible} ${isOpen ? style.isOpen : ''}`}>
        <div className={`${style.step} ${isOpen ? style.active : ''}`}>
          {step}
        </div>

        <div className={style.title}>
          <PText weight="bold" size="medium">
            {title}
          </PText>

          {!isOpen ? <>{closedContent}</> : null}
        </div>

        {!isOpen && !shouldHideEditButton ? (
          <div className={style.buttonContainer}>
            <PButtonPure icon="edit" onClick={() => handleEditButtonClick()}>
              {toggleOpenButtonLabel}
            </PButtonPure>
          </div>
        ) : null}
      </div>

      {isOpen ? <div className={style.openContent}>{children}</div> : null}

      {hideActionButton || !isOpen ? null : (
        <div className={style.actionButtonContainer}>
          <PButton
            disabled={isActionButtonDisabled}
            onClick={handleActionButtonClick}
          >
            {actionButtonText}
          </PButton>

          {secondaryActionButtonText && handleSecondaryActionButtonClick ? (
            <PButton
              className={style.secondaryButton}
              onClick={handleSecondaryActionButtonClick}
              variant="secondary"
            >
              {secondaryActionButtonText}
            </PButton>
          ) : null}
        </div>
      )}
    </div>
  );
};
