import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  success: {
    defaultMessage:
      'Contract successfully terminated. You’ll receive an e-mail confirmation shortly.',
    id: 'flyout.terminate.success',
  },
  confirm: {
    defaultMessage: 'Are you sure you want to terminate the contract?',
    id: 'flyout.terminate.confirm',
  },
  action: {
    defaultMessage: 'Terminate',
    id: 'flyout.terminate.action',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'flyout.terminate.cancel',
  },
  errorTitle: {
    defaultMessage: 'Failed to terminate the contract',
    id: 'flyout.terminate.error.title',
  },
  errorText: {
    defaultMessage: 'Please try again later.',
    id: 'flyout.terminate.error.text',
  },
  terminate: {
    defaultMessage: 'Terminate the contract',
    id: 'flyout.terminate.terminate',
  },
  terminateSubtitle: {
    defaultMessage:
      "Before you go, we’d appreciate it if you could share why you’re terminating your susbscription. Your feedback helps us improve. Confirm your termination when you're ready.",
    id: 'flyout.terminate.terminateSubtitle',
  },
  terminateSuccessTitle: {
    defaultMessage: 'Termination successful',
    id: 'flyout.terminate.terminateSuccessTitle',
  },
  reasonSellingVehicle: {
    defaultMessage: 'Selling vehicle',
    id: 'flyout.terminate.reasonSellingVehicle',
  },
  reasonExpensive: {
    defaultMessage: 'Charging too expensive',
    id: 'flyout.terminate.reasonExpensive',
  },
  reasonBaseFee: {
    defaultMessage: 'Base fee too high',
    id: 'flyout.terminate.reasonBaseFee',
  },
  reasonChargingProblems: {
    defaultMessage: 'Too many problems while charging',
    id: 'flyout.terminate.reasonChargingProblems',
  },
  reasonInfrastructure: {
    defaultMessage: 'Lack of infrastructure',
    id: 'flyout.terminate.reasonInfrastructure',
  },
  reasonBetterOffer: {
    defaultMessage: 'Found better offer',
    id: 'flyout.terminate.reasonBetterOffer',
  },
  reasonOther: {
    defaultMessage: 'Other (please specify)',
    id: 'flyout.terminate.reasonOther',
  },
  additionalFeedbackLabel: {
    defaultMessage: 'Let us know if you have any additional feedback:',
    id: 'flyout.terminate.additionalFeedbackLabel',
  },
});
