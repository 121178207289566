import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext } from 'react';

import { Entitlement } from '../VehicleContext';

interface InclusiveOnlyProps {
  children: ReactNode;
}

/**
 * Displays children if the current vehicle is entitled to the inclusive flow
 */
export const InclusiveOnly: React.FunctionComponent<InclusiveOnlyProps> =
  observer((props) => {
    const context = useContext(vehicleContext);

    if (context.entitlement !== Entitlement.Inclusive) {
      return null;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  });
