import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  heading: {
    defaultMessage:
      'Your Porsche Charging Service Subscription has been suspended',
    id: 'manage.notifications.suspended.heading',
  },
  heading2: {
    defaultMessage: 'You have unpaid invoices on some of your contracts',
    id: 'manage.notifications.suspended.heading2',
  },
  description: {
    defaultMessage:
      'Your subscription has been suspended due to missed payments. Once the overdue amount is paid, your subscription will automatically be reinstated.',
    id: 'manage.notifications.suspended.description',
  },
  description2: {
    defaultMessage:
      'You are not able to activate new Porsche Charging Service contracts until you pay the remaining invoices.',
    id: 'manage.notifications.suspended.description2',
  },
});
