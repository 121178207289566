import { PHeading, PText } from '@porsche-design-system/components-react';
import { planContext } from 'components/context/plan/PlanContext';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { observer } from 'mobx-react-lite';
import { PaymentData } from 'pages/activation/components/PaymentWidget/usePaymentWidget';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';

import { messages } from './paiementForm.messages';
import style from './style.module.scss';
import { PaymentWidget } from '../../../activation/components/PaymentWidget';

interface IProps {
  onPaymentMethodChanged: (paymentMethodId: string | null) => void;
  setApplePayToken?: (token: string | null) => void;
  isRedesign?: boolean;
}

const PaiementForm: React.FC<IProps> = observer(
  ({ onPaymentMethodChanged, setApplePayToken, isRedesign = false }) => {
    const authentication = useAuthentication();
    const params = useParams<{
      marketplace: string;
      vin: string;
      locale: string;
    }>();

    const [searchParams, setSearchParams] = useSearchParams();

    const context = useContext(planContext);

    const handleApplePayToken = useCallback(
      (token: string) => {
        setApplePayToken?.(token);
      },
      [setApplePayToken],
    );

    const handlePaymentMethodChange = useCallback(
      (paymentData: PaymentData) => {
        if (!paymentData) {
          onPaymentMethodChanged(null);
        }
        setApplePayToken?.(null);
      },
      [onPaymentMethodChanged, setApplePayToken],
    );

    const handlePaymentIdChange = useCallback(
      (paymentId: string) => {
        if (
          searchParams.get('errorActionCode') ||
          searchParams.get('status') ||
          searchParams.get('paymentMethodId') ||
          searchParams.get('paypal')
        ) {
          searchParams.delete('errorActionCode');
          searchParams.delete('status');
          searchParams.delete('paymentMethodId');
          searchParams.delete('paypal');
          setSearchParams(searchParams);
        }
        onPaymentMethodChanged(paymentId);
      },
      [onPaymentMethodChanged, searchParams, setSearchParams],
    );

    return (
      <>
        {isRedesign ? (
          <PText>
            <FormattedMessage {...messages.paymentMethodExplanationText} />
          </PText>
        ) : (
          <>
            <PHeading tag="h2" size="x-large">
              <FormattedMessage {...messages.title} />
            </PHeading>
            <PText size="small" className={style.py2}>
              <FormattedMessage {...messages.subtitle} />
            </PText>
          </>
        )}
        <PaymentWidget
          apiKey={authentication.apiKey}
          authorizationToken={authentication.token}
          marketplace={params.marketplace || ''}
          locale={params.locale || 'en_US'}
          onChange={handlePaymentMethodChange}
          onChangePaymentId={handlePaymentIdChange}
          redirectUrl={window.location.href}
          shouldUseIntegratedLogic
          currency={context.currencyCode}
          handleApplePayTokenSave={handleApplePayToken}
        />
      </>
    );
  },
);

export default PaiementForm;
