import { PTextFieldWrapper } from '@porsche-design-system/components-react';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './businessVerification.messages';
import style from './businessVerification.module.scss';

export type BusinessData = {
  key: string;
  value: string;
};

interface VatVerificationProps {
  hasError?: boolean;
  legalName: string;
  onChange: (data: BusinessData) => void;
  vat: string;
}

export const BusinessVerification: FC<VatVerificationProps> = ({
  hasError = false,
  legalName,
  vat,
  onChange,
}) => {
  const intl = useIntl();

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { name, value } }) => {
      onChange({ key: name, value });
    },
    [onChange],
  );

  return (
    <div className={style.root}>
      <PTextFieldWrapper
        label={intl.formatMessage(messages.legalNameInputLabel)}
      >
        <input
          name="legalName"
          onChange={handleOnChange}
          required
          type="text"
          value={legalName}
        />
      </PTextFieldWrapper>
      <PTextFieldWrapper
        label={intl.formatMessage(messages.vatIdInputLabel)}
        state={hasError ? 'error' : 'none'}
      >
        <input
          name="vat"
          onChange={handleOnChange}
          required
          type="text"
          value={vat}
        />
      </PTextFieldWrapper>
    </div>
  );
};
