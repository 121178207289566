import { PFlyout, PHeading } from '@porsche-design-system/components-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomerService } from './customerService/CustomerService';
import { DetailsOverview } from './overview/DetailsOverview';
import { messages } from './suspendedFlyout.messages';
import style from './suspendedFlyout.module.scss';
import { SuspendedNotificaion } from '../notifications/suspended/Suspended';

export const SuspendedFlyout: React.FunctionComponent = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const open =
    location.pathname.match(/.*\/(contract|history)\/suspended$/i) !== null;

  const onClose = useCallback(() => {
    navigate('./..');
  }, [navigate]);

  return (
    <PFlyout open={open} onDismiss={onClose}>
      <span slot={'header'}>
        <PHeading>
          <FormattedMessage {...messages.title} />
        </PHeading>
      </span>
      <div className={style.root}>
        <SuspendedNotificaion />

        <DetailsOverview />

        <div className={style.customerService}>
          <CustomerService />
        </div>
      </div>
    </PFlyout>
  );
});
