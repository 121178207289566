import { PButton, PText } from '@porsche-design-system/components-react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedLayout } from 'components/layout/frosted/FrostedLayout';
import background from 'media/backgroundFailure.jpg';
import { FC } from 'react';

import style from '../failurePage.module.scss';
import { useErrorCodes } from '../useErrorCodes';

type FailureComponentProps = {
  errorCodeOverride?: string;
  requestIdOverride?: string;
  suspendedVin?: string;
};

export const FailureComponent: FC<FailureComponentProps> = ({
  errorCodeOverride,
  requestIdOverride,
  suspendedVin,
}) => {
  const { errorCodeContent, errorCode, requestId } = useErrorCodes(
    errorCodeOverride,
    requestIdOverride,
    suspendedVin,
  );

  return (
    <div className={style.mainContainer}>
      <FrostedLayout background={background} shouldShowNewMobileDesign>
        <FrostedHeading data-testid="failure-title">
          {errorCodeContent?.title}
        </FrostedHeading>
        {errorCodeContent?.subtitle ? (
          <FrostedItem data-testid="failure-subtitle">
            <PText size="medium">{errorCodeContent.subtitle}</PText>
          </FrostedItem>
        ) : null}
        {errorCodeContent?.subtitleTwo ? (
          <FrostedItem data-testid="failure-subtitle">
            <PText size="medium">{errorCodeContent.subtitleTwo}</PText>
          </FrostedItem>
        ) : null}

        <PText color="contrast-medium" className={style.errorCodeText}>
          {errorCode ? errorCode : ''} {requestId ? ` ${requestId}` : ''}
        </PText>

        <div>
          <PButton
            className={style.button}
            onClick={errorCodeContent?.primaryButtonAction}
          >
            {errorCodeContent?.primaryButton}
          </PButton>

          {errorCodeContent?.secondaryButton ? (
            <PButton
              variant="secondary"
              className={style.button}
              onClick={errorCodeContent?.secondaryButtonAction}
            >
              {errorCodeContent?.secondaryButton}
            </PButton>
          ) : null}
        </div>
      </FrostedLayout>
    </div>
  );
};
