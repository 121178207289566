import { Auth0Client, AuthorizationParams, User } from '@auth0/auth0-spa-js';
import {
  getResolvedPathParams,
  validatePath,
} from 'hooks/useValidateUrlPattern';
import * as process from 'process';
import { json, LoaderFunction } from 'react-router-dom';

import {
  clearOAuthRedirectUrl,
  getOAuthRedirectUrl,
  setOAuthRedirectUrl,
} from './oauthState';

export type AuthorizationData = {
  authorizationToken: string;
  widgetApiKey: string;
  ciamId: string;
};

type PidUser = User & {
  ciamId: string;
};

const defaultPorscheIdScopes = [
  'pid:user_profile.name:read',
  'pid:user_profile.vehicles:read',
  'pid:user_profile.emails:read',
  'pid:user_profile.addresses:read',
];

let auth0: Auth0Client;

export function getAuth0Client() {
  if (auth0) {
    return auth0;
  }
  const porscheIdAuthEndpoint = new URL(
    process.env.REACT_APP_PORSCHE_PROFILE_AUTH_URL ||
      'https://identity.porsche.com',
  );

  auth0 = new Auth0Client({
    domain: porscheIdAuthEndpoint.hostname,
    clientId: process.env.REACT_APP_PORSCHE_PROFILE_CLIENT_ID!,
    cacheLocation: 'memory',
  });

  return auth0;
}

export const oauthCallback: LoaderFunction = async ({ params: _ }) => {
  const auth0 = getAuth0Client();

  try {
    await auth0.handleRedirectCallback();
    const callbackUrl = getOAuthRedirectUrl();
    clearOAuthRedirectUrl();

    return json({ callbackUrl });
  } catch (e) {
    const error: Error = e as Error;

    console.error(error);

    return {
      error,
      message: error.message,
    };
  }
};

interface TokenLoaderProps {
  params: {
    locale?: string;
    marketplace?: string;
    vin?: string;
  };
}

/**
 * Token loader used for user authentication <br/>
 * Indirectly used at {@link useAuthentication}
 */
export const tokenLoader = async (
  props: TokenLoaderProps,
): Promise<{} | AuthorizationData> => {
  const auth0 = getAuth0Client();
  const callbackUrl = `${window.location.origin}/oauth/callback`;
  const pathParams = getResolvedPathParams({
    locale: props.params.locale,
    marketplace: props.params.marketplace,
  });

  const validatedPathname = validatePath({
    pathname: window.location.pathname,
    locale: props.params.locale,
    marketplace: props.params.marketplace,
    vin: props.params.vin,
  });

  const authorizationParams: AuthorizationParams = {
    audience: process.env.REACT_APP_PORSCHE_PROFILE_AUDIENCE!,
    redirect_uri: callbackUrl,
    ui_locales:
      (pathParams ? pathParams.locale : props.params.locale) || 'en-GB',
    scope: defaultPorscheIdScopes.join(' '),
  };

  try {
    const token = await auth0.getTokenSilently({ authorizationParams });
    const user: PidUser | undefined = await auth0.getUser();
    if (!user) {
      throw new Error('No user data available!');
    }

    return {
      authorizationToken: token,
      widgetApiKey: process.env.REACT_APP_PORSCHE_PROFILE_CLIENT_ID!,
      ciamId: user.ciamId,
    };
  } catch (e) {
    const auth0Error: { error: string } = e as any;

    if (auth0Error.error === 'login_required') {
      setOAuthRedirectUrl(
        (validatedPathname
          ? `${validatedPathname}${window.location.search}`
          : window.location.href
        ).replace(window.location.origin, ''),
      );

      await auth0.loginWithRedirect({
        authorizationParams,
      });
      return {};
    }

    return {};
  }
};

export const logout = () => {
  throw new Error('Not implemented yet');
};
