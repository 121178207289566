import { logErrorToNewRelic } from 'components/misc/newRelic/NewRelic';
import { fetchApi } from 'utils/fetchApi';

export type TBusinessVerificationStruct = {
  legalName: string;
  vat: string;
  country: string;
};

export const fetchBusinessVerification = async ({
  legalName,
  vat,
  country,
  authorizationToken,
  ciamId,
}: {
  authorizationToken: string;
  ciamId: string;
} & TBusinessVerificationStruct) => {
  const requiredValues = { legalName, vat, country };

  let isVerified = false;

  try {
    const result = await fetchApi(
      `${process.env.REACT_APP_BASE_API_URL}/vat/verification`,
      authorizationToken,
      {
        method: 'POST',
        body: JSON.stringify(requiredValues),
      },
    );

    if (!result.ok) {
      logErrorToNewRelic(new Error('Business verification failed'), {
        ciamId,
        vatId: requiredValues.vat,
      });
      return false;
    }

    isVerified = await result.json();
  } catch (error) {
    logErrorToNewRelic(error, {
      ciamId,
      vatId: requiredValues.vat,
      additionalInfo: 'Business verification failed',
    });
    isVerified = false;
  }

  return !!isVerified;
};
