import { PDivider, PWordmark } from '@porsche-design-system/components-react';
import { LocaleContextProvider } from 'components/context/locale/LocaleContextProvider';
import { defaultLocale } from 'hooks/useParams';
import React, { ReactNode } from 'react';

import style from './blankLayout.module.scss';
import { ContentWrapper } from '../contentWrapper/ContentWrapper';
import { Footer } from '../footer/Footer';

interface BlankLayoutProps {
  children: ReactNode;
  locale?: string;
}

export const BlankLayout: React.FunctionComponent<BlankLayoutProps> = ({
  children,
  locale = defaultLocale,
}) => {
  return (
    <LocaleContextProvider>
      <div className={style.root}>
        <PWordmark className={style.header} />
        <PDivider />
        <ContentWrapper>{children}</ContentWrapper>
        <div className={style.footer}>
          <Footer locale={locale} />
        </div>
      </div>
    </LocaleContextProvider>
  );
};
