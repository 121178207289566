import { PlanVariant } from 'components/context/plan/planContext.types';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext } from 'react';

import { planContext } from '../../PlanContext';

interface Props {
  children?: ReactNode;
}

export const PremiumOnly: React.FunctionComponent<Props> = observer((props) => {
  const context = useContext(planContext);

  if (
    context.variant === PlanVariant.V2_PREMIUM ||
    context.variant === PlanVariant.V2_PREMIUM_INCLUSIVE
  ) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return null;
});
