import { observer } from 'mobx-react-lite';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import {
  SubscriptionContext,
  subscriptionContext,
  subscriptionDummyContext,
} from './SubscriptionContext';
import { vehicleContext } from '../vehicle/VehicleContextProvider';
import { useAuthentication } from '../../../hooks/useAuthentication/useAuthentication';

interface SubscriptionContextProviderProps {
  children?: ReactNode;
}

/**
 * Manages and rebuilds the SubscriptionContext
 */
export const SubscriptionContextProvider: React.FunctionComponent<SubscriptionContextProviderProps> =
  observer((props) => {
    const auth = useAuthentication();
    const vehicle = useContext(vehicleContext);
    const [context, setContext] = useState(subscriptionDummyContext);

    useEffect(() => {
      if (vehicle.vin === '') {
        return;
      }
      setContext(
        new SubscriptionContext({
          auth: auth,
          vehicle: vehicle,
          dummy: false,
        }),
      );
    }, [auth, vehicle]);

    return (
      <subscriptionContext.Provider value={context}>
        {props.children}
      </subscriptionContext.Provider>
    );
  });
