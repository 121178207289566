import { PButtonPure, PText } from '@porsche-design-system/components-react';
import { Subscription } from 'components/context/subscription/SubscriptionContext';
import { useRoutes } from 'hooks/useRoutes';
import { useShouldShowPriceUpdate } from 'hooks/useShouldShowPriceUpdate';
import { observer } from 'mobx-react-lite';
import { PriceChangeTag } from 'pages/management/components/priceChangeTag/PriceChangeTag';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'router/routes';

import { messages } from './baseFee.messages';
import style from './baseFee.module.scss';
import { InclusivePeriod } from './InclusivePeriod';
import { planContext } from '../../../../context/plan/PlanContext';

type BaseFeeProps = {
  subscription?: Subscription | null;
};
/**
 * Row to display the monthly base fee of a contract
 */
export const BaseFee: React.FunctionComponent<BaseFeeProps> = observer(
  ({ subscription }) => {
    const context = useContext(planContext);
    const navigate = useNavigate();
    const generateRoute = useRoutes();
    const { shouldShowPriceUpdate, dateOfPriceUpdate } =
      useShouldShowPriceUpdate({ subscription });

    return (
      <div data-testid={'baseFee'}>
        <div className={style.title}>
          <PText>
            <FormattedMessage {...messages.monthlyTitle} />
          </PText>
        </div>
        {subscription ? <PriceChangeTag subscription={subscription} /> : null}
        <div className={style.infoContainer}>
          <div className={style.container}>
            <PText weight={'bold'} align={'right'} className={style.text}>
              <FormattedMessage
                {...messages.monthlyText}
                values={{ baseFee: context.baseFee }}
              />
            </PText>
            <InclusivePeriod />
          </div>
          {shouldShowPriceUpdate && dateOfPriceUpdate ? (
            <div className={style.priceChangeButton}>
              <PButtonPure
                icon="arrow-right"
                onClick={() => navigate(generateRoute(Routes.PriceUpdate))}
              >
                <FormattedMessage {...messages.priceChangeButtonLabel} />
              </PButtonPure>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);
