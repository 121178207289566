import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './disclaimer.messages';
import { NorthAmericaOnly } from '../../../context/plan/discriminators/region/NorthAmericaOnly';
import { BasicOnly } from '../../../context/plan/discriminators/tariff/BasicOnly';
import { LegacyOnly } from '../../../context/plan/discriminators/tariff/LegacyOnly';
import { NarPremiumInclusiveOnly } from '../../../context/plan/discriminators/tariff/NarPremiumInclusiveOnly';
import { NarPremiumOnly } from '../../../context/plan/discriminators/tariff/NarPremiumOnly';
import { planContext } from '../../../context/plan/PlanContext';

/**
 * Non styled disclaimer for pricing and taxes of current {@link planContext} <br/
 * Disclaimer should always be in the current plan context, if not existing then in the premium plan context
 * @constructor
 */
export const Disclaimer: React.FunctionComponent = observer(() => {
  const context = useContext(planContext);

  return (
    <>
      <NorthAmericaOnly>
        <LegacyOnly>
          <div>
            *<FormattedMessage {...messages.excluded} />
          </div>
        </LegacyOnly>
        <BasicOnly>
          <div>
            *<FormattedMessage {...messages.seeInApp} />
          </div>
          <div>
            **
            <FormattedMessage {...messages.excluded} />
          </div>
        </BasicOnly>
        <NarPremiumInclusiveOnly>
          <div>
            *<FormattedMessage {...messages.seeInApp} />
          </div>
          <div>
            **
            <FormattedMessage {...messages.excluded} />
          </div>
        </NarPremiumInclusiveOnly>
        <NarPremiumOnly>
          <div>
            *
            <FormattedMessage
              {...messages.excludedYearly}
              values={{
                monthly: context.baseFee,
                yearly: context.yearlyBaseFee,
              }}
            />
          </div>
          <div>
            **
            <FormattedMessage {...messages.seeInApp} />
          </div>
          <div>
            ***
            <FormattedMessage {...messages.excluded} />
          </div>
        </NarPremiumOnly>
      </NorthAmericaOnly>
    </>
  );
});
