import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'adressForm.title',
    defaultMessage: 'Set your desired address',
    description: 'Title of the address form',
  },
  subtitle: {
    id: 'adressForm.subtitle',
    defaultMessage:
      'Please check your address information or set a new address.',
    description: 'Subtitle of the address form',
  },
  addNewAddress: {
    id: 'adressForm.addNewAddress',
    defaultMessage: 'Add new billing address',
    description: 'Add address button',
  },
  billingAdress: {
    id: 'adressForm.billingAdress',
    defaultMessage: 'Billing address',
    description: 'Billing Adress list',
  },
  chargingCardDeliveryAddressLabel: {
    id: 'adressForm.chargingCardDeliveryAddressLabel',
    defaultMessage: 'Delivery address for your Porsche Charging Card',
    description: 'Delivery address for your Porsche Charging Card',
  },
  sameAddressCheck: {
    id: 'adressForm.sameAddressCheck',
    defaultMessage: 'Corresponds to the billing address',
    description: 'Corresponds to the billing address',
  },
  actionButtonLabel: {
    id: 'adressForm.actionButtonLabel',
    defaultMessage: 'Save & continue',
    description: 'Action button label',
  },
});
