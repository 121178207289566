import {
  PButton,
  PButtonPure,
  PHeading,
  PIcon,
  PInlineNotification,
} from '@porsche-design-system/components-react';
import { messages } from 'pages/management/components/subscriptionManagement/subscriptionManagement.messages';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './checkoutStep.module.scss';

export enum CheckoutStepStatus {
  Active = 'active',
  Completed = 'completed',
  Inactive = 'inactive',
}

interface CheckoutStepProps {
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  isEditDisabled?: boolean;
  hasError?: boolean;
  onEdit?: (step: number) => void;
  onSubmit?: () => void;
  hideSubmit?: boolean;
  status: CheckoutStepStatus;
  stepId: number;
  stepNumber: number;
  submitLabel?: string;
  title: string;
  errorMessage?: string;
}

export const CheckoutStep: FC<PropsWithChildren<CheckoutStepProps>> = ({
  children,
  isLoading = false,
  isSubmitDisabled = false,
  isEditDisabled = false,
  hasError = false,
  onEdit,
  onSubmit,
  hideSubmit = false,
  status,
  stepId,
  stepNumber,
  submitLabel,
  title,
  errorMessage,
}) => {
  const indicatorClassName = useMemo(() => {
    if (status === CheckoutStepStatus.Active) {
      return style.stepIndicatorActive;
    }

    if (status === CheckoutStepStatus.Completed) {
      return style.stepIndicatorCompleted;
    }

    return style.stepIndicatorInactive;
  }, [status]);

  const showContent = useMemo(
    () =>
      [CheckoutStepStatus.Active, CheckoutStepStatus.Completed].includes(
        status,
      ),
    [status],
  );

  const handleEditStep = useCallback(() => {
    if (onEdit) {
      onEdit(stepId);
    }
  }, [onEdit, stepId]);

  return (
    <div className={style.root}>
      <div className={style.header}>
        <div className={indicatorClassName}>
          {status === CheckoutStepStatus.Completed ? (
            <PIcon name="success" />
          ) : (
            stepNumber
          )}
        </div>
        <PHeading size="large">{title}</PHeading>
        {onEdit && status === CheckoutStepStatus.Completed ? (
          <PButtonPure
            onClick={handleEditStep}
            icon="edit"
            disabled={isLoading || isEditDisabled}
          >
            <FormattedMessage {...messages.editButtonText} />
          </PButtonPure>
        ) : null}
      </div>
      {showContent ? children : null}
      {showContent && hasError && (
        <div className={style.error}>
          <PInlineNotification
            dismissButton={false}
            state="error"
            description={errorMessage}
          />
        </div>
      )}
      {!hideSubmit &&
      status === CheckoutStepStatus.Active &&
      submitLabel &&
      onSubmit ? (
        <div className={style.submitButton}>
          <PButton
            onClick={onSubmit}
            disabled={isSubmitDisabled}
            loading={isLoading}
          >
            {submitLabel}
          </PButton>
        </div>
      ) : null}
    </div>
  );
};
